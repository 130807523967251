import React from 'react';
import { Icon } from 'semantic-ui-react';
import getNestedObject from '../helpers/getNestedObject';
import gtm from '../providers/gtm';
import Badge from 'views/NUI/Badge';
import { cls } from 'views/NUI/utils';
import onepass_model from '../../models/onepass_model';
import Buttons from './Buttons';
import IconMed from './IconMed';

const img_path = 'https://storage.googleapis.com/instant-med-public/Cat/';
const def_img = `${img_path}-MumlMcDcm0-PVKqzz3S.jpg`;

export default function Card({
	el, cat_nm, El, props, to, cat_loc, el_conf, CMI, el_conf_cst,
	onProductClick, info_link, alt_name, multiDayCert 
}) {

	const icosm = app.settings.icosm;
	const HeaderTileComponent = icosm ? 'h4' : 'h3';

	return (
		<React.Fragment key={el.t == 'l' || el.t === 'r' ? to.pathname + el.k : to.pathname}>
			<div
				className={cls("rounded-lg shadow", "grid-col-1 bg-white flex justify-between ", "flex-col")}
				onClick={onProductClick}
			>
				<El
					data-testid="link-tile"
					className="h-full flex flex-col justify-between text-left"
					{...props}
				>

					{el.t != 'x'  && !icosm && <div className="hidden md:block h-[140px] rounded-t-lg bg-cover bg-center" style={{ backgroundImage: el.k === 'DocConsFertility' ? `url(${def_img})` : `url(${img_path}${el.k == "ShortConsPatho" ? "ShortConsRefr" : el.k}.jpg), url(${def_img})` }} />}

					<div className="px-6 pt-5">
						<div className={cls('text-gray-800', 'flex', CMI && 'pr-6', 'flex-col items-start justify-start')}>
							{(el.t == 'm' || el.t == 'l') && !cat_nm == 'patho' && <IconMed height="20" width="20" marginRight="0.5em" />}
							<HeaderTileComponent className={cls({ "font-bold": !icosm }, "!text-[24px] mb-1")} data-testid='header-tile'>{el.n}</HeaderTileComponent>
							{cat_loc === 'certs' && !multiDayCert && <h4 className='font-normal mt-0'>Single-day certificate</h4>}
						</div>
						{alt_name && <p className='text-gray-500 leading-4 mt-2 mb-0'><strong>Generic name:</strong> {alt_name}</p>}
						{el.d && <div className={cls(icosm ? 'text-gray-500' : 'text-is-black-80', 'mt-4')}>{el.d.split("\n").map((line, i) => <p className='mb-0' key={i}>{line}&nbsp;</p>)}
						</div>}
					</div>

					<div className="mt-auto px-5 pb-3 md:pb-4 text-sm">
						{el_conf_cst && (
							<Badge
								testId='text-cost'
								small className='mt-5 mr-2 !px-4 text-is-gray-100 bg-is-blue-20'
							>
								{isNaN(el_conf_cst) && el_conf_cst
									|| <>
										Cost:
										{['-NGF7Ol56bf-dd5Omm9s', '-NDR2NvDFQNoVlkuElj1', '-MUH7qPzqHTHOl4RrNPg'].includes(el.k) && ' From'}
										&nbsp;${el_conf_cst}
									</>
								}
							</Badge>
						)}
						{
							onepass_model.isValidOnepassMember()
							&& onepass_model.isValidOnepassItem(
								app.app_data.ws_data.cat_data?.pharm?.cat_show_list[el.k]
								|| getNestedObject(app.app_data.ws_data.cat_data?.pharm?.cat_tree_list, window.location.pathname.split('/').pop() + '/i')?.[el.k]
							)
							&& <>
								<br />
								<Badge testId='text-op-cost' small className='mt-4 mr-2 text-is-gray-100 bg-is-blue-20'>
									OnePass Member Cost:
									{['-NGF7Ol56bf-dd5Omm9s', '-NDR2NvDFQNoVlkuElj1', '-MUH7qPzqHTHOl4RrNPg'].includes(el.k) && ' From '}
									{" "}
									{onepass_model.discountedPriceString(el.k === '-NGF7Ol56bf-dd5Omm9s' && 70 || el_conf_cst || 20)}
								</Badge>
							</>
						}
					</div>
				</El>

				<Buttons
					cta={
						[el.par_id, el.l, cat_loc].includes('certs') && !multiDayCert && `Request ${el.n} Certificate` ||
						el_conf('lnk_txt') != "no" && el_conf('lnk_txt') ||
						(el.t == 'l' || (el.t == 'x' && el.lnk == '/m/pharm/DocCons/DocConsCov')) && 'Request Consultation' ||
						app.dvc?.org?.type !== 'cosm' && (
							el.t == 'r' && 'Request Script' ||
							([el.par_id, el.l, cat_loc].includes('express-specialist-referrals') || [el.par_id, el.l, cat_loc].includes('docref') || [cat_nm, el.par_id, el.l, cat_loc].includes('patho')) && 'Request Referral' ||
							[el.par_id, el.l, cat_loc].includes('certs') && !multiDayCert && 'Request Certificate' ||
							([el.par_id, el.l, cat_loc].includes('subscr') || [el.par_id, el.l, cat_loc].includes('DocCons') || ([el.par_id, el.l, cat_loc].includes('certs') && multiDayCert)) && 'Request Consultation'
						)
					}
					cta_link={props}
					info_link={el_conf('inli_lnk') || info_link || el.IL}
					el={el}
					exp
				/>

				{CMI &&
					<a onClick={e => { e.preventDefault(); e.stopPropagation(); gtm.log('', 'outbound', { link: CMI, loc: 'card', med: el }); window.open(CMI, '_blank').focus() }} href={CMI} target='info'
						className='absolute top-4 right-4 text-blue-500 hover:text-blue-800'
					>
						<Icon name='info circle' />
					</a>
				}

			</div>
		</React.Fragment>
	)
}
