import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import UniForm from 'xAppLib/UniForm/UniForm';
import script_model from 'models/script_model';
import API_service from 'xAppLib/providers/API_service';
import localstorage_database from 'xAppLib/providers/localstorage_database';
import gtm from 'xAppLib/providers/gtm';
import { useProfile } from 'xAppLib/Hooks';
import cart_model from 'models/cart_model';
import { PARTN } from "../../constants";
import { useDocs } from './Hooks';
import {
	calculate_types,
	data_from_profile,
	get_form_data,
	get_qs,
	get_gtm_product,
	missing_profile_fields,
	requestAppReview,
	sort_qs,
	update_cart,
	log_scr_req,
	get_c2u,
	aus_post_estimate,
	needs_shipping,
	make_answs,
	get_answer_flags,
	ans_flag_block,
	get_related_presc,
} from './utils';
import logger from 'xAppLib/libs/logger';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { ihi_valid } from './utils';
import org_model from 'models/org_model';
import evermed_model from 'models/evermed_model';
import user_model from "models/user_model";
import Section from './Section';
import SearchPharm from 'views/UIelems/SearchPharm';
import obj_filter_by_key from "../../xAppLib/helpers/obj_filter_by_key";
import { useAsync } from '../../xAppLib/Hooks/useAsync';
import medicare_model from 'models/medicare_model';
import { useDebouncedValue } from '../../xAppLib/Hooks';
import onepass_model from '../../models/onepass_model';
import { formatComponents } from '../../helpers/address';
import {form_persistence_model} from "../../models/form_persistence_model";

function enableEvermed(ref_prid, is_treatment_plan, enable_fulfilment, med_supp_id){
	const plan_id = app.user.prof?.pts_prescs?.find(p => [p.par, p.prid].includes(ref_prid))?.par;
	const sup_id = app.user.prof?.pts_plans?.find(p => p.tpid === plan_id)?.tp_sup_id ?? med_supp_id;
	switch (sup_id){
		// If the order has previously been fulfilled by Evermed, show evermed options
		case PARTN.EVERMED:
			return true
		// If the order has previously been fulfilled by Amcal, show normal medbuy options
		case PARTN.DUKASA:
			return false
		default:
			return is_treatment_plan || enable_fulfilment
		// Default for new orders
	}
}

function make_cart(req_type, script_type, free_consult) {
	return new cart_model({req_type, script_type, free_consult})
}

function scrollToTop() {
	(app.settings.is_ionic ? document.getElementById('app') : window).scrollTo(0, 0)
}

const DEBUG = false
const DEBUG_GTM = false

const C2U_ENABLE = true

const MAX_FORM_ERR_CNT = 1
const HOME_DELIVERY_ENABLED = true
const ANS_FLG_INSTABLOCK = true;


function form_track(evt, status) {
	DEBUG && console.log("form_track",evt,status);
	gtm.event(evt, `${evt}_${status}`, {error:status!=='success'} )
}



const MedForm = ({med_data, med_mb, med_code, dataProp, sid, cat_route, cat_nm, children, prop_req_type,prop_script_type, medbuy_only, patho_request, free_consult, allow_set_usr}) => {
    const location = useLocation()
	const [forms, setForms] = useState({})

	const [profile, profiles] = useProfile()
	const [activeProfile, setActiveProfile] = useState(() => {
		return location.state?.pts_uid && profiles.find(p => p.uid === location.state.pts_uid) || profiles[0];
	});
	const presc = get_related_presc({ profile:activeProfile, med_code });

	const qs_exprcons = new URLSearchParams(location.search).has('speak-to-a-doctor');
	const [defaultValues,setDefaultValues] = useState(() => ({
		profile: Math.max(0, profiles.indexOf(activeProfile)),
		src_mid: location.state?.src_mid,
		exprcons_want: qs_exprcons,
		...(activeProfile?.hist?.some(x => x.form_data?.sharps === 'yes') && { sharps: 'no' })
	}));
	const [valids,setValids] = useState({})
	const [validsErrors,setValidsErrors] = useState({})
	const [values,setValues] = useState({...defaultValues})
	const fieldsRef = useRef({})

	const paymToken = useRef(null)
	const paypalOrderDetails = useRef(null)
	const submitRef = useRef(null)
	
	const [meta,setMeta] = useState({})

	const [ihi,setIHI] = useState({})
	const [onepassEligible, setOnepassEligible] = useState();
	const [ihi_load,setLoadingIHI] = useState(false)

	const [c2u,setC2U] = useState({})
	const [aus_post_delivery,setAusPost] = useState({})

	const [showErrors,setShowErrors] = useState(false)
	const [sending,setSending] = useState(false)
	const [page_state, setPageState] = useState('form')
	const [step,setStep] = useState("start")
	const [scrReqAvailRsp, setScrReqAvailRsp] = useState(null)
	const scrReqAvail = scrReqAvailRsp && (!scrReqAvailRsp.open||scrReqAvailRsp.msg) ? scrReqAvailRsp : null
	const qualcons = scrReqAvailRsp?.qualcons;
	const qual_status = qualcons?.qualification_status ?? null;

	// ? Maybe a better way to do these? 
	const [form_err_cnt,setErrorCount] = useState(0)
	const [ans_flags,setAnsFlags] = useState([])
	const [form_res_err_msgs,setResErrMsgs] = useState(null)
	const [form_res_msgs,setResMsgs] = useState(null)
	const [form_send_res,setSendRes] = useState(null)
	const [CC_pay_err, setCCPayErr] = useState(null)

	/**
	 * Runtime settings
	 * TODO go through and clean up if neded
	 */
	const PARTN_PHARM = app.runtime.partn_pharm_oid || false
	const home_delivery_available = app.acl.app_use 
						&& (!PARTN_PHARM || org_model.org_conf_itm(app.runtime.org, 'is_mb_disp')) 
						&& HOME_DELIVERY_ENABLED 
						&& med_data.can_delivery 
						&& org_model.org_conf_itm(app.runtime.org, 'is_mb') 
						&& !app.runtime.phapick_only
						&& !org_model.org_conf_itm(app?.runtime?.org, 'phapick_only')
						&& !org_model.org_conf_itm(app?.user?.org, 'phapick_only')

	const [req_type, script_type] = calculate_types({values, med_code, med_data, cat_nm, cat_route, profile:activeProfile ,prop_req_type,prop_script_type,PARTN_PHARM, home_delivery_available, medbuy_only, presc, qual_status })
	const is_spon = app?.acl?.is_spon;

	app.state.cat_nm = cat_nm
	const use_profile = app.user.user_in 
							&& !is_spon
							&& app.acl.app_use
							&& script_type!='docscr' 
							// && !app.user.claims.admin

	const need_ihi =  app.acl.app_use && !is_spon && ['medcons', 'medbuy', 'medclick', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick', 'doccons', 'medsub', 'subcons'].includes(script_type) && !['medcert', 'docord', 'followup_cron', 'followup_res', 'doccons_fu'].includes(req_type)  /*&& ['premed', 'remed', 'cosm'].includes(req_type) */ && !app.user?.prof?.xtra?.alw_noihi
							&& !(app.runtime.app=='icann' && app.dev_env)
	const has_ihi = ihi_valid(ihi)
	const need_extra_info = !presc && /augmentin|keflex|amoxycillin|flucloxacillin/.test(med_data.name?.toLowerCase())

	const validsErrorsMsg = Object.keys(valids).filter(k=>!valids[k]).map(k=> k == 'general' && "There is a problem validating your details. Please check the form." || validsErrors[k] || `Unknown error – ${k}` )

	const qs = useMemo(()=>get_qs(med_data, values.selected_items, {dob:values.dob}),[med_data, values.selected_items, values.dob])
	const qs_sorted = useMemo(()=>sort_qs(qs),[qs])

	const cart = useMemo(()=>make_cart(req_type, script_type),[])
	const {docs:docs_data_db, docs_rost} = useDocs(script_type)

	const { cat_avail_list } = dataProp
	const srch_str = location.state?.srch_str

	const credit = use_profile && activeProfile?.meta?.cred || 0
	const formData =
		get_form_data({med_code, values, cart, profile, paymToken, has_form_errs : false, location, is_spon, qualcons});

	const is_treatment_plan = script_type === 'medsub';

	const need_medicare = med_data.xtra?.needs_mc_verf;
	const { data: has_medicare } = useAsync({
		immediate: need_medicare || ['delivery', 'click_collect'].includes(formData.fulfilment),
		fn: async () => activeProfile && (await medicare_model.medicare_verify({ uid: activeProfile.uid }))?.res === 'ok'
	}, [activeProfile]);

	const med_supp_oid = med_mb?.medbuy_supp_oid;
	const [evermed, setEvermed] = useState({enabled: enableEvermed(formData.ref_prid, is_treatment_plan, false, med_supp_oid), do: [], m: [], cnc: []})

	update_cart(cart, {values, script_type, req_type, use_profile, free_consult, evermed, credit})

	useEffect(()=>{
		SearchPharm.load_pharms()
	},[])
	/**
	 * view settings
	 * TODO go through and clean up if neded
	 */
	const allow_age = 18
	const child = 15
	// const primary_dob = this.use_profile && this.primary_profile.dob || formData.dob
	const primary_dob = use_profile && activeProfile?.dob || values.dob
	const valid_age = useMemo(() => moment().diff(primary_dob, 'years') >= allow_age, [primary_dob, allow_age]);
	const child_age = useMemo(() => moment().diff(primary_dob, 'years') < child, [primary_dob, child]);
	const prof_miss = useMemo(() => missing_profile_fields(activeProfile, need_ihi&&!has_ihi), [activeProfile, need_ihi, has_ihi]);
	const prof_compl = prof_miss.length==0
	const acc_must_verif = prof_compl && (need_ihi && !has_ihi || need_medicare && !has_medicare)
	const med_unsuit = ((xtra) => {
		if (!app.acl.app_use)		return false;

		if (xtra.gend_only && activeProfile?.sex && xtra.gend_only != activeProfile.sex) return true;

		const age = moment().diff(activeProfile?.dob, 'years') || 0
		if (xtra.min_age && age < xtra.min_age) return true;
		if (xtra.max_age && age > xtra.max_age) return true;

		return false;
	})(med_data?.xtra || {});
	useEffect(() => med_unsuit && form_track('unsuit_err error', '-Med unsuitable for user'), [med_unsuit])
	
	const is_max_error = form_err_cnt>MAX_FORM_ERR_CNT
	const is_rpt_error = form_send_res?.err_type === 'repeat';
	const is_cosm = script_type=='cosm' || req_type == 'cosm'
	const is_cosm_med = script_type=='medcons' && req_type=='cosm'
	const is_pharm_med = script_type=='pharm' && req_type=='pharm'
	const is_cado = !!values.cado_want
	const is_lacking_patho = med_data?.need_patho && !values.patho_res_sid;
	const is_user_prof_required = !['cosm', 'pharm'].includes(script_type) && !['cosm', 'pharm'].includes(req_type) && app.runtime.mode !== 'HE' && !prof_compl && !is_spon;
	const is_pharm = app.dvc?.org?.type=='pharm'

	const has_bmi_requirement = Boolean(med_data.xtra?.min_bmi || med_data.xtra?.max_bmi); // not including min_weight here, only enable that if bmi requirements exist
	const has_weight_requirement = Boolean(activeProfile?.parent && child_age);
	const is_presc = app.user?.prof?.pts_prescs?.filter( pr => pr?.med?.mid==med_code )?.length > 0
	const qs_for_presc = !!med_data.xtra?.qs_for_presc;


	const persistence = useMemo(
		() => app.user?.uid && is_cosm && med_code ? new form_persistence_model(app.user.uid, 'MedForm', med_code) : null,
		[app.user?.uid, is_cosm, med_code]
	);

	const can_req_cons = ['pharm', 'medcons', 'medbuy', 'medclick', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick'].includes(script_type)
		&& !['cosm', 'icann', 'ileg', 'medcert'].includes(req_type)
		&& app.runtime.mode!='caia'
		&& !org_model.org_conf_itm(app.runtime.org, 'hide_consults')
		&& !org_model.org_conf_itm(app.user?.org, 'hide_consults');

	const is_qualcons = ['qualcons', 'qualbuy', 'qualclick'].includes(script_type);
	const allow_rpt_exprcons = ['-LJlvQo2RunU9oi9abk5'/* ANTIHYPERTENSIVE (BP) */].includes(cat_route);
	const is_rpt_exprcons = is_rpt_error && allow_rpt_exprcons && Object.keys(qs).every(qkey => qkey in formData && qs[qkey]?.a?.[formData[qkey]]?.res === "p");
	const has_force_exprcons = Object.keys(qs).some(qkey => qkey in formData && qs[qkey]?.a?.[formData[qkey]]?.must_xc);
	const is_exprcons_avail = app.settings.is_exprcons_avail
		&& !is_qualcons
		&& valid_age
		&& !is_pharm
		&& !!med_data?.xc_avail
		&& Object.keys(qs).every(qkey => qs[qkey]?.a?.[formData[qkey]]?.no_xc !== true)
		&& (
			// either there must be a fail answer (otherwise why are we getting a doctor to speak to them?)
			Object.keys(qs).some(qkey => qkey in formData && qs[qkey]?.a?.[formData[qkey]]?.res === "f")
			// or there must be a question that forces an exprcons (e.g. under 40 and taking antihypertensives)
			|| has_force_exprcons
			// or this is a med that allowed repeats via express consult (and the rest of the form has
			// been answered correctly, a given if we're to the point of repeat check, but just in case)
			|| is_rpt_exprcons
		);

	// needed to re-arrange some flags to allow profile to switch sub profiles after one of those have been blocked by bad questions, similar to med-unsuit
	// some products have express consult available if the questions were answered wrong but without triggering any kill switch,
	// we will check if it is available before determining if to hide the form after the profile
	const enable_exprcons = is_exprcons_avail && can_req_cons && (is_rpt_exprcons || is_max_error || qs_exprcons || has_force_exprcons);
	// if there's no express cons available we will utilise the mechanic from med-unsuit to still show the user profile and the ability to switch sub profiles
	// later on in show_form remove the condition to show err (which blocks the profile as well) based on bad question attempts
	const hide_aft_dets = (is_lacking_patho || is_cado || acc_must_verif || med_unsuit || is_user_prof_required || is_max_error) && !enable_exprcons;

	const is_compounded = [PARTN.DUKASA].includes(med_mb?.medbuy_supp_oid);

	const enable_fulfilment = ['medcons', 'exprcons', 'exprbuy', 'medbuy', 'qualcons', 'qualbuy', 'medclick', 'qualclick', 'exprclick'].includes(script_type) && !['medcert', 'dcs_cert', 'dcs_refr', 'dcs_patho'].includes(req_type) && app.runtime.mode === 'iscr' && med_data?.epresc?.AMT_MPP !== undefined && (med_data.xtra?.no_deliv !== true && med_data.xtra?.no_click !== true) && !hide_aft_dets

	const has_escript = !!( (((['medcons','medbuy', 'medclick', 'exprcons','exprbuy', 'exprclick', 'qualcons','qualbuy', 'qualclick'].includes(script_type)) && ['premed', 'remed', 'cosm'].includes(req_type)) || (is_cosm_med) || (is_pharm_med) || (is_treatment_plan) || (is_spon)) && med_data.epresc )

	const has_delivery = is_compounded || (!(['cosm', 'doccons', 'pathoreq', 'subcons' ,'docrefr', 'medcert', 'medsub'].includes(script_type) || ['medcert'].includes(req_type)) && !enable_fulfilment);
	
	const can_escript = !!(has_escript && (has_ihi || is_cosm_med || is_pharm_med || is_spon)) || [PARTN.DUKASA, PARTN.BLS, PARTN.C2U].includes(med_mb?.medbuy_supp_oid)
	const delivery_methods = has_delivery && [
		home_delivery_available && app.runtime.partn_type!="c2u" /*&& med_mb?.medbuy_supp_oid==PARTN.PA*/ &&

		((is_treatment_plan && !evermed.enabled) || !is_treatment_plan) && (app.runtime?.mode !== 'iscr' || is_compounded) && "home",
		!is_treatment_plan && home_delivery_available && med_mb?.medbuy_supp_oid==PARTN.PA && C2U_ENABLE && c2u.ok && !enable_fulfilment && app.runtime?.mode !== 'iscr' && "c2u",
		!is_treatment_plan && home_delivery_available && med_mb?.medbuy_supp_oid==PARTN.PA && C2U_ENABLE && !(c2u.ok) && !enable_fulfilment && app.runtime?.mode !== 'iscr' &&"c2u_err",
		!is_treatment_plan && !medbuy_only && has_escript && 'escript',
		!is_treatment_plan && !is_spon && !medbuy_only && (!can_escript || !need_ihi) && !is_pharm && "pharm"
	].filter(Boolean).reduce((r,k)=>Object.assign(r,{[k]:true}),{}) || {}

	const enable_need_patho = !!(med_data?.need_patho) && !med_unsuit;
	const enable_confirm_video = ['DocConsMHCP','DocConsMHCPCopay','DocConsMHCPReview'].includes(med_code) || req_type === 'cosm';
	const enable_patho = script_type=='pathoreq'
	const enable_profile = use_profile
	const enable_pts_dets = !use_profile
	const enable_doc_cons = !hide_aft_dets && (['doccons', 'subcons', 'docrefr', 'exprcons', 'qualcons', 'exprbuy', 'qualbuy', 'exprclick', 'qualclick'].includes(script_type) && !is_lacking_patho || req_type === 'medcert') || has_bmi_requirement
	const enable_doc_presc = script_type=='docscr'
	const enable_shipping=!hide_aft_dets && ['medbuy', 'exprbuy', 'qualbuy', 'medsub'].includes(script_type) && !enable_fulfilment
	const enable_extra_info = need_extra_info
	const enable_delivery = !hide_aft_dets && has_delivery //(app.settings.iprep || ((home_delivery_available || has_escript) && !medbuy_only))
	const enable_pharm = !hide_aft_dets && ((home_delivery_available||has_escript) && (values.delivery == 'pharm' || values.delivery == 'escript') || !has_escript && !home_delivery_available && ['medcons', 'doccons', 'docscr'].includes(script_type)) && !['medcert', 'ileg', 'icann', 'ihair', 'docconswl', 'docconswli', 'docconswlo', 'mhcp', 'mhcprev', 'dcs_cert', 'dcs_refr', 'dcs_patho'].includes(req_type) && !is_pharm && !enable_fulfilment
	const enable_cado = !is_qualcons && (is_cado || (!enable_exprcons && !hide_aft_dets && can_req_cons) && !is_presc);
	const enable_qs = (!hide_aft_dets || is_cado) && script_type!='pthl-needlestick' && (!is_presc || qs_for_presc) && (!enable_exprcons || has_force_exprcons);
	const enable_summary = !hide_aft_dets && (cart.MEDCONS_PRICE || script_type=='pharm' && is_cado || ['medbuy', 'medcons', 'exprbuy', 'exprcons', 'qualbuy', 'qualcons', 'doccons', 'subcons', 'medsub', 'medclick', 'exprclick', 'qualclick' ].includes(script_type))
	const enable_paym = !hide_aft_dets && (cart.content().total.value>0) && !is_spon;
	const enable_cosm_treat = !hide_aft_dets && script_type=='cosm'
	const enable_sign = !hide_aft_dets && req_type!='paas' && !is_presc;
	const enable_yogp = !hide_aft_dets
	const enable_alt_pharm = !hide_aft_dets && script_type=='pharm'
	const enable_skype = !!(is_cosm && !app.settings.is_COSM_INSTCONS_NEED)
	const enable_cosmdoc = !!(is_cosm && app.settings.is_COSM_INSTCONS_NEED)
	const enable_noti = !app.settings.ileg && !is_spon && !hide_aft_dets && ['doccons','medcons','medbuy', 'medclick', 'exprcons','exprbuy', 'exprclick', 'qualcons','qualbuy', 'qualclick', 'subcons'].includes(script_type)
	const enable_doc_warn = need_extra_info;
	const enable_disclaimer = !hide_aft_dets
	const enable_medicare_warn = req_type=='docconscov'  && values['-M2MkLvymogzqCEG2x-l']
	const enable_treatment_plan = !hide_aft_dets && is_treatment_plan;
	const enable_medselection = is_treatment_plan && evermed.enabled
	const enable_standalone_evermed_delivery = !is_compounded && is_treatment_plan
	const enable_dva_section = evermed.enabled && values.dva?.colour === 'W'
	const enable_click_and_collect = !medbuy_only && !!app.site_status?.enable_click_collect && med_data?.xtra?.disable_click_collect !== true
	const enable_qualcons = !enable_yogp && is_qualcons;
	const enable_myhr = !hide_aft_dets && use_profile  // TODO finalise details
	const is60day = !!(med_data?.xtra?.is60day);
	const enable_refer = !['DocConsMHCP','DocConsMHCPCopay'].includes(med_code);

	const show_form = page_state=='form'
		|| (page_state=='form_err'&&(enable_exprcons || !(ANS_FLG_INSTABLOCK&&ans_flag_block([...ans_flags, ...get_answer_flags(formData, qs)]))))
		|| (page_state=='form_sent_err' && (!['repeat', 'flagged', 'req_invalid_error'].includes(form_send_res?.err_type) || is_rpt_exprcons))
	const show_hdr = app.user.user_in && show_form && !(is_exprcons_avail && (is_max_error || is_rpt_exprcons));
	const show_sending = page_state=='form_sending'
	const show_complete = page_state=='form_sent_ok' || page_state=='form_sent_cado_ok'
	const show_form_err = page_state=='form_err' || "paym_errors" == form_send_res?.err_type || page_state=='form_sent_err' && (["mcnvr_errors", "mcvr_errors", "dvavr_errors", "ihivr_errors"].includes(form_send_res?.err_type) || is_rpt_exprcons)
	const show_continue = !hide_aft_dets
	const show_YOGP = ['doccons', 'medcons', 'exprcons', 'qualcons', 'docrefr', 'medsub', 'subcons'].includes(script_type) && !['medcert', 'cosm', 'icann', 'ileg'].includes(req_type);
	const show_MyHR = ['doccons', 'medcons', 'exprcons', 'qualcons', 'docrefr', 'medsub', 'subcons'].includes(script_type) && !['cosm', 'icann', 'ileg'].includes(req_type);

	useEffect(()=>{
		if (is_cosm && app.settings.is_COSM_INSTCONS_LOG) {
			logger.report_error('ERROR in MedForm - InstCons – no Media', 'error',{
				navigator:navigator,
				mediaDevices:navigator?.mediaDevices,
				getUserMedia:navigator?.mediaDevices?.getUserMedia
			});
		}
	},[])

	const view = {
		is_cosm,
		is_cado,
		is_exprcons_avail,
		is_spon,
		is60day,
		enable_patho,
		enable_profile,
		enable_shipping,
		enable_pts_dets,
		enable_doc_cons,
		enable_doc_presc,
		enable_qs,
		enable_extra_info,
		enable_delivery,
		enable_qualcons,
		enable_exprcons,
		enable_click_and_collect,
		enable_cado,
		enable_summary,
		enable_paym,
		enable_cosm_treat,
		enable_sign,
		enable_yogp,
		enable_myhr,
		enable_alt_pharm,
		enable_skype,
		enable_cosmdoc,
		enable_pharm,
		enable_noti,
		enable_doc_warn,
		enable_treatment_plan,
		enable_medselection,
		enable_dva_section,
		enable_need_patho,
		enable_confirm_video,
		enable_standalone_evermed_delivery,
		enable_refer,
		show_form,
		show_hdr,
		show_sending,
		show_complete,
		show_form_err,
		enable_disclaimer,
		enable_medicare_warn,
		show_continue,
		show_YOGP,
		show_MyHR,
		qual_status,
		is_qualcons,
		qualcons,
		has_bmi_requirement,
		has_weight_requirement,
		enable_fulfilment,
        ...(scrReqAvail?.onepass && {onepass: scrReqAvail.onepass}),
	};

	const settings = { PARTN_PHARM, MAX_FORM_ERR_CNT, use_profile, prof_compl, is_user_prof_required, prof_miss, has_escript, free_consult, ihi_load, need_ihi, has_ihi, acc_must_verif, med_unsuit, c2u, aus_post_delivery, need_extra_info, can_escript, docs_data_db, docs_rost, home_delivery_available, onepassEligible}

	const gtm_product = useMemo(_=>get_gtm_product({script_type, req_type, med_data, cart,srch_str,cat_nm, cat_route, cat_avail_list}),[script_type, req_type, med_data, cart, cart.med, cart.free_consult, cart.want_generic, srch_str,cat_nm, cat_route, cat_avail_list])

	DEBUG && console.log({req_type,script_type})
	DEBUG && console.log({cart})
	DEBUG && console.log({formData})
	DEBUG && console.log({delivery_methods})
	DEBUG_GTM && console.log({gtm_product})

	useEffect(()=>{
		setActiveProfile(profiles[values.profile || 0])
	},[profiles,values.profile])

	// Med changes
	useEffect(()=>{
		if (!med_data)
			return
		
		cart.add_med(med_data)
		
		
	},[med_data, script_type, req_type, cart])
	
	// GTM
	useEffect(()=>{
		
		const product = get_gtm_product({script_type, req_type, med_data, cart,srch_str,cat_nm, cat_route, cat_avail_list})
		DEBUG_GTM && console.log("GTM add",product)
		gtm.details(product)
		gtm.add_to_cart(product)
		return () => {
			DEBUG_GTM && console.log("GTM remove",product)
			gtm.remove_from_cart(product)	
		}
	},[cart])


	useEffect(()=>{
		logger.log_MedReq_form_step({step: 'enter', script_type, req_type, med_nm: med_data?.name})
		return () => {
			logger.log_MedReq_form_step({step: 'leave', script_type, req_type, med_nm: med_data?.name})
			// On unmount, reload profile to clean up prescs when exiting from presc medform. 
			app.user.reload_profile()
		}
	},[])

	// If you have multiple treamtment plan review banners and click between them, the medform doesn't unmount or refresh the evermed enabled.
	// So you are stuck with an order which has been fulfilled previously with amcal and shows evermed options. This useEffect forces the enabled to refresh
	// and update accordingly.
	useEffect(() => {
		setEvermed({enabled: enableEvermed(formData.ref_prid, is_treatment_plan, enable_fulfilment, med_supp_oid), do: [], m: [], cnc: [], loading: true})
	}, [formData.ref_prid, is_treatment_plan, enable_fulfilment, med_supp_oid])

	/**
	 * check ihi
	 * TODO could be turned into re-usable hook
	 */
	 const get_ihi = useCallback(async (activeProfile)=>{
		if (!activeProfile)
			return
		setLoadingIHI(true)
		try {
			const ihi = await API_service.load_data( 'User/myIhi', { uid:activeProfile.uid, parent:app.user.uid } )
			if (ihi?.res == 'ok') {
				setIHI({
						number : ihi.ihi,
						status : ihi.ihiStatus,
				})
			}
		} catch (e) { }
		setLoadingIHI(false)

	},[])

	const checkOnepassEligibility = useCallback(async (activeProfile)=>{
		try {
			setOnepassEligible(await API_service.load_data( 'User/myOnepass', { token: activeProfile.onepass } ));
		} catch (e) { }
	},[])

	// Profile update, check profile changes and re-calculate defaults 
	useEffect(()=>{
		if (!activeProfile || !use_profile)
			return
			
		const profile_data = data_from_profile(activeProfile)

        onepass_model.isValidOnepassMember() && checkOnepassEligibility(activeProfile);

		// setValues(values=>({...values,...profile_data}))
		setDefaultValues(prevDefaults => {
			const isAddressDifferent = values?.shipping_address !== prevDefaults?.shipping_address;
			return {
				...prevDefaults,
				...profile_data,
				shipping_address: isAddressDifferent && values?.shipping_address
					|| activeProfile['full_address'] && activeProfile['full_address'].formatted
					|| '',
				shipping_address_components: isAddressDifferent && values?.shipping_address_components 
					|| activeProfile['full_address'] 
					|| '',
				cons_phone:profile_data.mobile,
				cons_desc:location.state?.cons_desc,
			}
		})
			
		const profile_ihi = {
			number : activeProfile.ihi_ihinumber,
			status : activeProfile.ihi_ihistatus,
		}

		setIHI(profile_ihi)

		if (!ihi_valid(profile_ihi)) {
			get_ihi(activeProfile)
		}

	},[activeProfile, get_ihi, use_profile])

	useEffect(() => {
		setValues(values=>{
			if (!ihi_valid(ihi) && values.delivery == 'escript' || ihi_valid(ihi) && values.delivery == 'pharm') {
				setValids(valids=>({...valids, delivery:false}))
				return {...values, send_to_pha: undefined, sendto_oid: undefined, delivery:null}
			}
			return values
		})
		
	}, [ihi])


	/**
	 * Calculate defaults
	 */
	useEffect(()=>{
		if (app.save_pt_form_data?.qa && qs) {
			// If the patient save data has details of the previous answered question and answer _text_, use that to
			// pre-fill the form for the current med. This is useful because we have many identical questions across
			// different med categories that have different question/answer IDs! Ideally we'd have some sort of Q&A
			// catalogue we would refer to, which also avoids having to update the same question/answer text in multiple
			// places (not that it happens often). But this is just a quick hack for cosmetics.
			for (const [qid, conf] of Object.entries(qs)) {
				if (!(qid in app.save_pt_form_data)) { // if there's already an answer using IDs, skip this
					const answerText = app.save_pt_form_data.qa.find(({q}) => q === conf.txt)?.a;
					if (answerText) {
						const aid = Object.keys(conf.a).find(aid => conf.a[aid].txt === answerText);
						if (aid) {
							app.save_pt_form_data[qid] = aid;
						}
					}
				}
			}
		}

		const def_vals =  app.runtime.allow_set_medform &&
								Object.fromEntries(app.runtime.get_params_init)
							|| allow_set_usr &&
								{
									first_name: app.runtime.get_params_init?.get("first_name") || '',
									last_name: app.runtime.get_params_init?.get('last_name') || '',
									email: app.runtime.get_params_init?.get('email') || '',
									dob: app.runtime.get_params_init?.get('dob') || '',
									medicare: app.runtime.get_params_init?.get('medicare') || '',
									mobile: app.runtime.get_params_init?.get('mobile') || '',
									// cado_phone: app.runtime.get_params_init?.get('mobile') || '',
									address: app.runtime.get_params_init?.get('address') || '',
									shipping_address_components: app.runtime.get_params_init?.get('address') || '',
									// conc_card: app.runtime.get_params_init?.get('conc_card') || '',
								}
							|| app.save_pt_form_data
							|| persistence?.retrieve()
							|| {};

	
		if (PARTN_PHARM) {
			def_vals.send_to_pha = { nm : app.runtime.org.name, adr : app.runtime.org.address}
			def_vals.sendto_oid = PARTN_PHARM
		}

		if (script_type=='cosm' && localstorage_database.get_record('last_skype_doc'))
			def_vals.cosm_doc = localstorage_database.get_record('last_skype_doc')

		if (patho_request)
			def_vals.selected_items = patho_request.tests.reduce((o,{key})=>({...o,[key]:true}),{}) // include all tests by default

		if (Object.keys(delivery_methods).length == 1)
			def_vals.delivery = Object.keys(delivery_methods).pop()
		setValues(values=>({...values, full_address: {...values?.full_address, ...(values?.full_address && {formatted: formatComponents(values.full_address)})}, ...def_vals}))
		setDefaultValues(defaultValues=>({...defaultValues, ...def_vals}))
	},[PARTN_PHARM, allow_set_usr,patho_request,script_type])


	/**
	 * Remed
	 */
	useEffect(_=>{
		if (!sid) 
			return 
		(async ()=>{

			try {
				const remed_scr = await API_service.load_data( 'remedScr', {sid, mid:med_code} )

				DEBUG && console.log('remed_scr', remed_scr);
				if (remed_scr) {
					const clean_remed_fields = obj => {
						return obj_filter_by_key(obj, key => !(
							obj[key] === '.' || ['fulfilment', 'medication', 'delivery', 'medicare', 'conc_card'].includes(key)
						));
					}

					remed_scr.form_data = clean_remed_fields(remed_scr.form_data);
					remed_scr.spd_data = clean_remed_fields(remed_scr.spd_data);

					const values = {
						...remed_scr.form_data,
						sendto_oid: app.user.claims?.pts_oid || remed_scr.form_data.sendto?.oid || null , 
						...remed_scr.spd_data,
						...(use_profile && activeProfile || {}),
					}
					// if (values.delivery=='home' && !home_delivery_available) {
					// 	values.delivery = 'pharm'
					// }
					setDefaultValues(defaultValues=>({...defaultValues,...values}))
				}
				
			} catch (e) {
				const url = new URL(location.pathname, window.location.origin)
				url.pathname = `/med/${cat_route}/${med_code}`
				app.history.push(`${url.pathname}${url.search}`);
				return
			} 
			
		})()

		
    },[activeProfile, cat_route, home_delivery_available,location.pathname, med_code, sid, use_profile])

	/**
	 * Load scrReqAvail
	 */
	useEffect(()=>{
		if (['exprbuy', 'exprclick', 'exprcons'].includes(script_type))
			return; // we dont want to double call scrReqAvail when the backend is still loading

		if (app.user.claims?.admin)
			return
			// && !(document.location.hostname=='localhost' && document.location.port=='9011')
			// && script_type!='cosm'

		(async ()=>{
			const scrReqAvail = await API_service.load_data( 'scrReqAvail', {
				script_type, 
				req_type, 
				med_code, 
				cat_route,
				uid: activeProfile?.uid,
				price: cart?.content()?.total?.value,
                onepass: { token: onepass_model.isValidOnepassMember(), avail: app?.site_status?.onepass_avail }
			} )

			if (scrReqAvail.res=='ok') {
				setErrorCount(scrReqAvail.form_err_cnt);

				if (scrReqAvail.answs) {
					setValues(prev => ({ ...scrReqAvail.answs,  ...prev }));
				}
				
				if (scrReqAvail.form_err_cnt>MAX_FORM_ERR_CNT) {
					setPageState('form_err')
				} else {
					setPageState('form')
				}
			}
			if (ANS_FLG_INSTABLOCK && Array.isArray(scrReqAvail.ans_flg) && ans_flag_block(scrReqAvail.ans_flg)) {
				setAnsFlags(scrReqAvail.ans_flg);
				setPageState('form_err');
			}

			scrReqAvail.res=='ok' && setScrReqAvailRsp(scrReqAvail)
		})()
		
	},[script_type, req_type, med_code, cat_route, activeProfile?.uid])

   
	useEffect(()=>{
		const def_vals = {}
		if (medbuy_only) {
			if ( home_delivery_available ) {
				def_vals.delivery = 'home'
				def_vals.fulfilment = 'delivery';
			} else {
				// TODO do we need to redirect out of the form or something?
				def_vals.delivery = undefined
			}
		}
		
		// setValues(values=>({...values, ...def_vals}))
		setDefaultValues(defaultValues=>({...defaultValues, ...def_vals}))
		
	},[medbuy_only, home_delivery_available]);


	
	const postcode = values.shipping_address_components?.postcode || defaultValues.shipping_address_components?.postcode
	const full_address = values.shipping_address_components || defaultValues.shipping_address_components
    useEffect(()=>{
		// if (!app.settings.iprep || !C2U_ENABLE)
		if (!C2U_ENABLE || med_mb?.medbuy_supp_oid!=PARTN.PA)
			return 
        (async ()=>{
            if (!postcode)  {
                setC2U({})
                return
            }
            setC2U({loading:true})
           	get_c2u({postcode, full_address}, (ret)=>{
				// console.log({ret});
				setC2U(ret)
				if (!ret.ok) {
					setValues(values=>{
							if (values.delivery!='c2u')
								return values
							setValids(valids=>({...valids, delivery:false}))
							return {...values, delivery:null}
						})

				}
			})
			
        })()
        
    },[postcode, full_address])

	const state = values.shipping_address_components?.state || defaultValues.shipping_address_components?.state
	useEffect(()=>{
		setAusPost(aus_post_estimate({ state }))
    },[state])

	useEffect(() => {
		// Likely the pt has been declined from ordering some med/app script and recommended to a consult
		// In any case, clear the delivery because we need to:
		// a) recalc possible delivery options for whatever this new med is; and,
		// b) recalc types, and delivery=c2u short circuits to medbuy, even though this might be a consult!
		setPageState('form');
		setValues(v => ({ ...v, delivery: null, exprcons_want: false, cado_want: false }));
	}, [med_code]);

	// Need to debounce suburb so not firing off so many request to the BE.
	const suburb = values.shipping_address_components?.suburb || defaultValues.shipping_address_components?.suburb
	const debounceCombined = useDebouncedValue(`${suburb} ${state} ${postcode}`, 500)
	// charge_sharps overwrites sharps status being sent in the getDeliveryAndMedOpts call.
	// We can decide whether to always charge delivery with sharps bin
	// or always charge delivery cost without sharps bin. 
	const charge_sharps = med_data?.xtra?.charge_sharps
	const sharps = charge_sharps !== undefined ? charge_sharps : values.sharps === 'yes';
	const isClickAndCollect = values.fulfilment === 'click_collect'
	useAsync(async () => {
		if(!(med_data.epresc?.AMT_MPP && full_address?.postcode?.length == 4 && (enable_fulfilment ? ['delivery', 'click_collect'].includes(values.fulfilment) : true))){
			return true
		}
		setEvermed(evermed=>({...evermed, m: [], do: [], cnc: [], loading: true}))
		const bufferMinutes = 
		['medbuy', 'medclick'].includes(script_type) && 15
		|| ['exprbuy', 'exprclick', 'qualbuy', 'qualclick'].includes(script_type) && app.site_status?.wr_stat?.prediction?.data?.pc_98_wait 
		|| 120
		const address = values.shipping_address_components?.address || defaultValues.shipping_address_components?.address
		const address2 = values.shipping_address_components?.address2 || defaultValues.shipping_address_components?.address2
		const em_data = await evermed_model.getDeliveryAndMedOpts({
				mppAmtCode: med_data.epresc.AMT_MPP,
				pbsCode: med_data.PBSCode || med_data.epresc?.PBSCode, 
				qty: med_data.epresc?.Quantity, 
				referenceTimestamp:  moment().utc().add( bufferMinutes, 'minutes').format(), // Used for offset to account for doccons delay.
				showCourier: !is_treatment_plan,
				...(!isClickAndCollect && {address}), // Only should have postcode for C&C for validation reasons
				...(!isClickAndCollect && {address2}),
				...(!isClickAndCollect && {state}),
				...(!isClickAndCollect && {suburb}), 
				...(sharps && {sharps}),
				...(med_data.xtra?.temp && {temp: true}),
				postcode,
				isClickAndCollect,
				showOutOfStockVariants: true,
			})
		const { medPrices, delOpts, delRes, medRes, clickRes, clickOpts, medErrRsn } = em_data
		setEvermed(evermed=>({
			...evermed,
			m: medPrices || [],
			do: delOpts || [],
			cnc: clickOpts || [],
			medRes,
			delRes, 
			clickRes,
			medErrRsn,
			loading: false
		}))
		isClickAndCollect && logger.usg_log('Loaded Click & Collect Options', null, {clickRes, cnc_options:clickOpts?.candidatePharmacies, postcode})
	},
		[med_data, values?.fulfilment, ...(enable_fulfilment ? [values.shipping_address] : [debounceCombined]), sharps]
	)

	const set_exprcons_want = enable_exprcons && (is_max_error || is_rpt_exprcons || has_force_exprcons);
	useEffect(() => {
		if (set_exprcons_want) {
			setValues(v => ({ ...v, exprcons_want: true }));
			setDefaultValues(v => ({ ...v, exprcons_want: true }));
		}
	}, [set_exprcons_want]);
	/**
	 * Form handlers
	 */
	const onUpdate = useCallback((v)=>{
		setValues(values=>{
			const upd = {...values,...v}
			DEBUG && console.log("onUpdate()",v,upd)
			if(v.shipping_address_components){
				upd.shipping_address = v.shipping_address_components.formatted
			}
			if (v.delivery || ['click_collect', 'delivery'].includes(v.fulfilment)) {
				if (needs_shipping(upd.delivery, upd.fulfilment)) {
					if (!values.shipping_address_components) {
						upd.shipping_address_components = JSON.parse(JSON.stringify(values.full_address))
						if (values.full_address?.formatted)
							upd.shipping_address = values.full_address?.formatted
					}
				}
			}
			if(v?.fulfilment === 'escript'){
				const values_to_clear = ['delivery', 'medication', 'selected_brand', 'shipping_address', 'shipping_address_components', 'col_pharm']
				values_to_clear.map(key => upd[key] = null)
				upd.delivery = 'escript'
			}
			if (['delivery', 'click_collect'].includes(v?.fulfilment)){
				const values_to_clear = ['send_to_pha', 'sendto_oid', 'delivery', 'medication', 'selected_brand', 'col_pharm']
				values_to_clear.map(key => upd[key] = null)
			}
			return upd
		})
		
		setPageState('form')

	},[])

	const onUpdateDefaults = useCallback((v)=>{
		DEBUG && console.log("onUpdateDefaults()",v)
		setDefaultValues(values=>({...values,...v}))
	},[])

	const onChange = useCallback((name, value, formValues)=>{
		const { sign: _ignore, ...form } = { ...values, [name]: value };
		persistence?.save(form);

		DEBUG && console.log("onChange()",name, value)
		
		logger.log_MedReq_form_step({step: 'form', script_type, req_type, med_nm: med_data?.name})

		if (name=="extra_Qs-understand") {
			return { cado_want: value=="understand-no"}
		}

		if (name == 'full_address' && value) {
			return { address: value.formatted }
		}
		if (name == 'shipping_address_components') {
			return { shipping_address: value.formatted}
		}

	},[values])


	const onFormChecked = useCallback((ret,vl, err)=>{
		setValids(valids=>({...valids,...vl}))
		setValidsErrors(errors=>({...errors,...err}))
	},[])
	
	/**
	 * Submission
	 */

	const doSend = useCallback((next_step, back_step)=>{
		DEBUG && console.log("doSend()",values,valids);

		const evt = 'send'

		if (submitRef.current) {
			logger.report_error('ERROR Double Post Med Form', 'error', {then:submitRef.current, now:new Date()});
			return
		}

		submitRef.current = new Date()

		// console.log('med_form_submit', app.user, formData, form_errs);

		let form_res_msgs = []
		let form_res_err_msgs = []

		
		qs &&
			Object.keys(qs)
			.map( (k) => {
						qs[k]?.a[values[k]]?.res=='p' && qs[k].a[values[k]].err_txt &&
							form_res_msgs.push(qs[k].a[values[k]].err_txt)

						qs[k]?.a[values[k]]?.res!='p'
							&& qs[k]?.a[values[k]]?.err_txt
							&& !(is_exprcons_avail && values.exprcons_want)
							&& !(['qualcons', 'qualbuy', 'qualclick'].includes(script_type) && !qs[k]?.a[values[k]]?.no_rc)
							&& form_res_err_msgs.push(qs[k].a[values[k]].err_txt)
					}
				)


		if ( (req_type === 'docconssc' || script_type!='doccons') && !valid_age )
			form_res_err_msgs.push(`You must be at least ${allow_age} years old to use this service.`)


		const form_errs = form_res_err_msgs

		const form_data = get_form_data({med_code, values, cart, profile : activeProfile, paymToken, has_form_errs : form_errs.length>0, paypalOrderDetails, location, is_spon, qualcons, onepass: scrReqAvailRsp?.onepass})

		if (med_data.xtra?.use_k10) {

			form_data.k10_score = 0;
			[1,2,3,4,5,6,7,8,9,10].map( n => form_data.k10_score += values['k10_'+n]*1 )

			// console.log('k10 on send', form_data.k10_score);

			if (form_data.k10_score >= 30 && !['exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick', 'doccons'].includes(script_type) /* they're already booking a consult, don't reject them! */)
				form_res_err_msgs.push("Please book telehealth appointment with our doctors immediately.")
		}

		if (form_res_err_msgs.length>0) {
			setErrorCount(val=>(val||0)*1 + 1)
			form_track(evt,'before-sending-error')
		}

		setResErrMsgs(form_res_err_msgs)
		setResMsgs(form_res_msgs)
		const state = form_res_err_msgs.length>0 ? 'form_err' : 'form_sending'

		setPageState(state)

		gtm.checkout({'step': state=='form_sending'?'sending':'error'},[gtm_product])

		Object.keys(form_data).map( f => typeof form_data[f] === 'string' && (form_data[f] = form_data[f].trim()) )

		let req_data = {
				req_type,
				script_type,
				form_data: {
					...form_data,
					...(form_err_cnt + 1 > MAX_FORM_ERR_CNT && { is_max_error: true })
				},
				answs: {},
				dvc: app.dvc,
				usr : app.user.user_det,
				prof: activeProfile?.uid,
				med: {id:med_code, route:cat_route},
				med_det: med_data,
				sign: form_data.sign,
				req_xtra: {
						mess_tok: app.mess_tok||0,
				},
				runt: app.runtime,
			}
		delete req_data.form_data.sign

		if ( script_type=='cosm' || req_type=='cosm' ) {

			req_data.alt_cat = '_'+cat_nm;

			req_data.cosm_det = {
				qua:values.cosm_qua,
				loc:values.cosm_loc,
				note:values.cosm_note,
				doc:values.cosm_doc,
				total_cost: values.cosm_total_cost,
				payment: values.cosm_payment,
				refund: values.cosm_refund,
				follow_up_payment: values.cosm_follow_up_payment,
				further_costs: values.cosm_further_costs,
			};

			req_data.dvc = {
				...req_data.dvc,
				webrtc: app.settings.is_IN_APP_VIDEO_SUPPORTED
			}
		}
		
		// join uploaded photos for the cons photos scenario - initially just sponsor org
		req_data.phts = [];
		if (form_data.hasOwnProperty("phts") && form_data?.phts?.length) {
            req_data.phts = [...req_data.phts, ...values.phts];
		}
        if (
            form_data.hasOwnProperty("cons_phts") &&
            form_data?.cons_phts?.length
        ) {
            req_data.phts = [...req_data.phts, ...values.cons_phts];
		}

		// if (req_type=='maas' && partn_oid)			req_data.partn = {oid: partn_oid, ptid: app.runtime.get_params_init?.get('ptid')};
		if (app.runtime.partn_type || app.runtime.partn_oid || app.runtime.get_params_init?.get('ptid')) {

			req_data.partn = {}

			if (app.runtime.partn_type)								req_data.partn.type = app.runtime.partn_type;
			if (app.runtime.partn_oid)								req_data.partn.oid = app.runtime.partn_oid;
			if (app.runtime.get_params_init?.get('ptid'))			req_data.partn.ptid = app.runtime.get_params_init?.get('ptid');
		}
		else if (profile?.pts_prescs?.filter( p => p.med?.mid==med_code )[0]?.ref) {

			req_data.partn = {}

			if (profile?.pts_prescs?.filter( p => p.med?.mid==med_code )[0].ref?.partn_oid) 					req_data.partn.oid = profile?.pts_prescs?.filter( p => p.med?.mid==med_code )[0].ref?.partn_oid;
			if (profile?.pts_prescs?.filter( p => p.med?.mid==med_code )[0].ref?.partn_ptid) 					req_data.partn.ptid = profile?.pts_prescs?.filter( p => p.med?.mid==med_code )[0].ref?.partn_ptid;
		}

		// req_data.req_type = req_type;
		// req_data.script_type = script_type;

		req_data.answs = make_answs(form_data, qs);
		req_data.form_data.has_escript = has_escript
		req_data.form_data.can_escript = can_escript

		if ( !form_errs.length>0 ) {
			setStep(next_step)

			req_data.form_errs = form_errs
			req_data.client_form_valid = !form_errs.length

			// console.log('Med :: med_form_submit ', req_data);
			// console.log('Med :: med_form_submit ', req_data, med_data.q, formData);

			// Update user profile if there are profile fields with new values (update_profile flag)
			const profileFields = Object.values(fieldsRef.current).filter((f) => f.update_profile && values[f.name] !== undefined && defaultValues[f.name] !== values[f.name])
			if (profileFields.length) {
				user_model.save_prof({
					uid: activeProfile.uid,
					...(activeProfile.uid !== app.user.uid ? { parent: app.user.uid } : {}),
					...profileFields.reduce((acc, p) => ({ ...acc, [p.name]: values[p.name] }), {})
				});
			}

			API_service.call_api(
									'reqScriptForm', 
									req_data,
									r => {
											r.res!='ok' && logger.report_error('ERROR Sending Script Request - MedForm.MedForm.doSend():706', 'error', {req_data, res: r});
											log_scr_req({sid:r.sid, err:r.res!='ok' && r,cart, script_type, req_type, req_data });
											const need_exprcons = r.err_type === 'repeat' && allow_rpt_exprcons;
											need_exprcons && setValues(v => ({ ...v, exprcons_want: true }));
											setSendRes(r)
											setPageState(r.res=='ok' ? 'form_sent_ok' : 'form_sent_err')
											r.res!='ok' && (
												["paym_errors", "mcnvr_errors", "dvavr_errors", "mcvr_errors","ihivr_errors"].includes(r.err_type)
												|| need_exprcons
											) && setStep(back_step)
											r.err_type === "paym_errors" && setCCPayErr({header: "We were unable to process your payment"})
											submitRef.current = null
											if (r.res=='ok') {
												const product = gtm_product
												const { price, shipping, tax } = product
												gtm.purchase({
														id: r.sid, 
														tax:tax,
														revenue: price,
														shipping: shipping,
														coupon: cart.discount?.code,
														affiliation: PARTN_PHARM && values.send_to_pha?.nm || 'InstantScripts'
													},
													[product]
												)
												gtm.mf(true, med_data?.name)

												logger.log_med_req_suc({
														sid: r.sid,
														price,
														script_type,
														req_type,
														partnm: PARTN_PHARM && values.send_to_pha?.nm || 'InstantScripts',
														med_nm: med_data?.name,
													})

												if (['doccons','medcons','medbuy','exprcons','exprbuy','qualcons','qualbuy', 'subcons'].includes(script_type))
													requestAppReview()
												
												if (app.dvc?.org?.type=='pharm') {
													app.do_not_be_here = app.history.location.pathname
													setTimeout(_ => app.do_not_be_here == app.history.location.pathname && app.history.push("/m/pharm/online/prescription"), 60*1000 )
												}

												form_track(evt,'success')

												const { ic_wr_key, cosm_wr_key } = r;
												if (ic_wr_key || cosm_wr_key) {
													logger.usg_log('MedForm', 'instcons', { ic_wr_key, cosm_wr_key });
													app.trigger(app.events.INSTCONS_WR_PENDING, [ic_wr_key || cosm_wr_key]);
													app.trigger(app.events.BOUNCE_RTDB, {force: app.settings.is_ios});
												}

												persistence?.clear();
											} else {
												form_track(evt,'response_error')
												gtm.mf(false, med_data?.name)
											}
									}, 

									r => { 
											logger.report_error('ERROR Sending Script Request - MedForm.MedForm.doSend():743', 'error', {req_data, res: r});
											submitRef.current = null
											setSendRes(r)
											setPageState('form_sent_err')
											setStep(back_step)
										}
								)
		} else {

			req_data.form_errs = form_errs
			req_data.client_form_valid = false

			console.log('Med :: med_form_submit :: ERROR :: ', req_data);
			// console.log('Med :: med_form_submit ', req_data, med_data.q, formData);

			log_scr_req({sid:null, err:'form_errors',cart, script_type, req_type, med_data});
			setStep(back_step)
			API_service.call_api(
									'reqScriptForm_err', 
									req_data,
									r => {
										console.log('reqScriptFormCall_err res', r)
										submitRef.current = null
									},
									r=> {
										submitRef.current = null
									}
								)
		}
		
		
	},[PARTN_PHARM, can_escript, cat_nm, has_escript, use_profile, med_data, script_type, req_type,  valids, cart, cat_route, values, profile, activeProfile, med_code, qs, gtm_product, is_exprcons_avail, form_err_cnt, valid_age, allow_rpt_exprcons, qualcons])

	
	 const onSubmit = useCallback((next_step, back_step)=>{
		DEBUG && console.log("onSubmit()",values,valids, forms);

		
		const evt = 'continue_'+next_step;

		form_track(evt,'pressed');
		
		(async () => {



			// Reset errors
			setCCPayErr(false)
			setShowErrors(false)

			// Check valids
			const ret = Object.values(valids).reduce( (t, e) => t = t && e, true )

			if (!ret) {
				setShowErrors(true)
				form_track(evt,'validation error')
				return 
			}

			if(forms.payment?.current.state.selected_card === 'paypal'){
				paymToken.current = null
			}

			if(forms.payment?.current.state.selected_card !== 'paypal'){
				paypalOrderDetails.current = null
			}

			// Get token if payment an option
			const cart_content = cart.content()
			if (cart_content.total.value > 0 && forms.payment?.current && forms.payment.current.state.selected_card !== 'paypal') {
				try {
					setSending(true)
					paymToken.current = await forms.payment.current.get_token()
				} catch (e) {
					console.log("got error ",e);
					setCCPayErr(e.message)
					/**
					 * Not logging user generated errors like
					 * 1.	Invalid card format
					 * 2.	Invalid card details
					 * 3.	Card details not entered
					 * 4.	Invalid card format (VISA, MASTER, etc...)
					 */
					if(!e.message || e.message.trim() === '' || !['Invalid card type format', 'Card Type not found', 'Invalid expiry month format', 'Invalid expiry year format', 'Missing card details', 'Invalid card details', 'Invalid card number format'].includes(e.message)){
						logger.report_error('ERROR getting payment token - MedForm.MedForm.onSubmit()', 'error', e);
					}
					form_track(evt,'payment error - '+e.message)
					return 
				} finally {
					setSending(false)
				}
			}
			
			// If next step is submit do compilation
			if (next_step == 'submit') {
				const form_data = get_form_data({med_code, values, cart, profile : activeProfile, paymToken, has_form_errs : false, location, is_spon, onepass: scrReqAvailRsp?.onepass})
				if (cart_content.total.value > 0 && !paymToken.current?.CC_tok && !['paypal', 'partnpay'].includes(form_data.paym?.paymentMethod))	{
					setCCPayErr("Unable to process your payment, please check the provided details.")
					// Not logging user error
					// logger.report_error('ERROR Missing payment details', 'error', {form_data, values, cart_content, token:paymToken.current});
					if (back_step) {
						setStep(back_step)
						gtm.checkout({'step': back_step},[gtm_product])
					}
					form_track(evt,'payment error - No token')
					return 
				}

				doSend(next_step, back_step)
			} else {
				setStep(next_step);
				scrollToTop()
			}
			form_track(evt,'success')
			gtm.checkout({'step': next_step},[gtm_product])
			
			logger.log_MedReq_form_step({step: next_step, script_type, req_type, med_nm: med_data?.name})

		})()

		
		
	},[med_code, activeProfile, values, valids, step, forms, cart, gtm_product, use_profile, doSend])
   



	/**
	 * utils
	 */
	const onMountSection = useCallback((section, ref, fields = [])=>{
		// Append fields from all forms and filter duplicates
		fieldsRef.current = {
			...fieldsRef.current,
			...Object.fromEntries(fields.map(f => [f.name, f]))
		};

		setForms(forms=>({...forms,[section]:ref}))
		if (!ref && fields) {
			// unmounting, clean up valids
			setValids(valids=>{
				for (let fl of fields) {
					if (fl.name) 
						delete valids[fl.name]
				}
				return {...valids}
			})
		}
		
	},[])

	const onMeta = useCallback(v=>{
		setMeta(meta=>({...meta,...v}))
	},[])
	 
	 
	const params = {
						req_type, 
						script_type,
						store:values, 
						formData,
						valids, 
						validsErrors,
						validsErrorsMsg,
						defaultValues, 
						sending,

						delivery_methods,
						cart, 
						qs,
						qs_sorted, 
						page_state,  
						showErrors,
						
						onUpdate, 
						onUpdateDefaults,
						onChange, 
						onSubmit,
						onFormChecked,
						onMountSection,
						
						step,
						setStep,
						
						CC_pay_err,
						form_err_cnt,
						ans_flags,
						form_res_err_msgs,
						form_res_msgs,
						form_send_res,
						
						scrReqAvail,

						meta,
						onMeta,

						...settings, 
						...view,

						Section,
						paypalOrderDetails,
						evermed, 
						has_medicare
					}

	return children?.(params)

}
 
export default MedForm;