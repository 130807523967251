import React, { useEffect, useState, useMemo } from 'react';
import { Form, Checkbox, Loader, Icon, List, Grid } from 'semantic-ui-react';
import moment from 'moment';
import script_model from 'models/script_model';
import UniFormMed from 'views/med/UniFormMed';
import AddressSearchNew from '../../../../xAppLib/UIelems/AddressSearchNew';
import API_service from '../../../../xAppLib/providers/API_service';
import Map from '../../../../xAppLib/Map/Map';
import logger from '../../../../xAppLib/libs/logger';
import Alert from '../../../NUI/Alert';

const EMPharmSelector = (props) => {
  const { Section, evermed, inline = false, formData } = props;
  const [geoLocation, setGeolocation] = useState({});
  const clickRes = evermed?.clickRes;
  const cnc = evermed?.cnc;

  const gMapsData = useMemo(
    () =>
      cnc?.candidatePharmacies?.map((pharm) => ({ ...pharm, type: 'pharmacy' })),
    [cnc?.candidatePharmacies]
  );

  const handlePharmSelection = ({ uf_this, pharm }) => {
    logger.usg_log('Selected Click & Collect pharmacy', null, {pharm})
    uf_this.handleInputChange(null, { name: 'delivery', value: `EM_${pharm.par_code}` });
    // col_pharm has the full pharm information, name, address, code etc.
    // Will be handy to have this saved in form data for comms and wording to pts
    uf_this.handleInputChange(null, { name: 'col_pharm', value: pharm });
  };

  const handleAddressSelection = (values, uf_this) => {
    if (values.geo) {
      const { address, address2, suburb, state, postcode, formatted } = values;
      uf_this.handleInputChange(null, { name: 'shipping_address_components', value: { address, address2, suburb, state, postcode, formatted } });
      // need to clear on address change
      uf_this.handleInputChange(null, { name: 'col_pharm', value: undefined });
    }
    return;
  };

  useEffect(async () => {
    const result = await API_service.load_data('geocode', { address: formData?.shipping_address_components?.formatted });
    const { lat, lng } = result.geometry && result.geometry.location;
    setGeolocation({ lat, lng });
  }, [formData?.shipping_address_components]);

  if (evermed.loading) {
    return <LoadingScreen formData={formData} />;
  }
  const img_path = "https://storage.googleapis.com/instant-med-public/icons/";

  return (
    <UniFormMed {...props} section="click_and_collect" fields={script_model.EM_CLICK_COLLECT_FIELDS}>
      {(values, valids, uf_this) => {
        return (
          <Section>

            {clickRes === 'ok' ? <Section.Header className={inline && 'm-0 mb-3 text-lg'}>Pharmacy selection</Section.Header> 
              : <Alert error className="!bg-[#FFEAE6]"
                  header="Which pharmacy would you like to collect your medication from?"
                  content={<><p>We are currently adding additional Click & Collect locations and more pharmacies will be available in future.</p>
                    <p>Please try a new location or select an alternate delivery option.</p></>}
                />
            }
            <Section.Content className={inline && 'p-0'} start={!inline}>
              {clickRes === 'ok' && <Form.Field label="Input your address and we will find a Click & Collect pharmacy near you." />}
              <AddressSearchNew
                value={clickRes === 'ok' && formData.shipping_address_components}
                onChange={(values) => handleAddressSelection(values, uf_this)}
                showLocate={true}
                field={clickRes !== 'ok' && { title: 'Try a new location', placeholder: 'Enter address', disable_profile: true }}
              />
              <Form.Field error={valids && !valids.delivery} className="pt-4">
                {clickRes === 'ok' && (
                  <>
                    <p className="text-gray-600 mt-2">Distance calculated using postcode. Distance may be inaccurate to your exact location.</p>
                    <div className="border mb-4 rounded-lg border-gray-200 overflow-hidden">
                      <Grid stackable>
                        <Grid.Row>
                          <Grid.Column width={8}>
                            <List divided relaxed className="flex-column justify-evenly overflow-y-auto" style={{maxHeight: '500px'}}>
                              {cnc?.candidatePharmacies?.map((pharm) => {
                                return (
                                  <List.Item key={pharm.code}>
                                    <Checkbox
                                      onClick={() => handlePharmSelection({ uf_this, pharm })}
                                      checked={values.col_pharm?.code === pharm.code}
                                      radio
                                      name="delivery"
                                      value={pharm.code}
                                      data-testid="radio-delivery-method"
                                      style={{ width: '100%' }}
                                      label={
                                        <label>
                                          <div>
                                            <div className="font-bold flex flex-row gap-3 w-100" style={{ alignItems: 'center' }}>
                                              {pharm.name.match(new RegExp(/(priceline)/i)) ? (
                                                <img style={{ height: 32 }} src="https://www.priceline.com.au/assets/images/favicon.ico" />
                                              ) :
                                                <img style={{ height: 32 }} src="https://storage.googleapis.com/instant-med-public/pharmacy.svg" />
                                              }
                                              <span>{pharm.name}</span>
                                              <em
                                                style={{
                                                  width: 74,
                                                  textAlign: 'center',
                                                  backgroundColor: 'rgb(0, 101, 242, 1)',
                                                  fontSize: 12,
                                                  borderRadius: 20,
                                                  color: 'white',
                                                }}
                                              >
                                                {pharm.prettyDistanceKms}
                                              </em>
                                            </div>

                                            <div className="flex flex-row gap-3 mt-2">
                                              <img style={{ marginLeft: 7 }} src={img_path+"icon-address.svg"} alt="address" />
                                              <p style={{ fontSize: 14 }} className="ml-3 mb-0">
                                                {pharm.prettyAddress}
                                              </p>
                                              <a
                                                href={`https://www.google.com/maps/search/?api=1&query=${pharm.name} ${pharm.prettyAddress} Australia`}
                                                target="_blank"
                                              >
                                                <Icon className="text-blue-600" style={{ fontSize: 11 }} name="external" />
                                              </a>
                                            </div>

                                            <div className="flex flex-row gap-3">
                                              <img style={{ marginLeft: 7 }} src={img_path+"icon-store-front.svg"} alt="phone" />
                                              <p style={{ fontSize: 14 }} className="ml-3">
                                                Open {pharm.formattedOpenHours.openHoursDay} {pharm.formattedOpenHours.prettyOpenTime} to {pharm.formattedOpenHours.prettyCloseTime}
                                              </p>
                                            </div>
                                            <div className="flex flex-row gap-3">
                                              <img style={{ marginLeft: 7 }} src={img_path+"icon-phone.svg"} alt="phone" />
                                              <p style={{ fontSize: 14 }} className="ml-3">
                                                Phone: {pharm.phoneNumber}
                                              </p>
                                            </div>
                                          </div>
                                        </label>
                                      }
                                    />
                                  </List.Item>
                                );
                              })}
                            </List>
                          </Grid.Column>
                          <Grid.Column width={8} only="tablet computer" style={{ maxHeight: '500px', paddingLeft: 0 }}>
                            {geoLocation.lat && geoLocation.lng && <Map
                              items={gMapsData}
                              streetViewControl={false}
                              center={{ location: geoLocation }}
                              showCenterPin={true}
                              showClusters={false}
                              renderInfoWindow={(pharm) => {
                                return pharm.name
                                  ? `
                                <div class="p-1">
                                  <h5 class="text-md">${pharm.name}</h5>
                                  <p class="mb-1">${pharm.prettyAddress}</p>
                                  <p>Open ${pharm.formattedOpenHours.openHoursDay} ${pharm.formattedOpenHours.prettyOpenTime} - ${pharm.formattedOpenHours.prettyCloseTime}</p>
                                  ${
                                    formData?.col_pharm?.name === pharm.name
                                      ? ``
                                      : `<button type="button" class="ui blue fluid button" onclick="markerSelectCallback('${pharm?.code}')">Select Pharmacy</button>`
                                  }
                                </div>`
                                  : pharm.isCenter
                                  ? 'You'
                                  : '';
                              }}
                              externallySelectedMarker={formData?.col_pharm}
                              onMarkerSelect={(pharm_code) => {
                                const col_pharm = cnc.candidatePharmacies.find((opt) => opt.code === pharm_code);
                                handlePharmSelection({ uf_this, pharm: col_pharm });
                              }}
                            />}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                    {formData?.col_pharm && (
                      <p className="text-gray-600 mb-0 mt-3">
                        Estimated time for your order to be ready is {moment(formData.col_pharm?.estimatedReadyAt).format('dddd, MMMM Do YYYY, h:mm a')}. Please
                        note this is an rough estimate only. A more accurate ETA will be provided in the confirmation email once your request has been approved.
                      </p>
                    )}
                  </>
                )}
              </Form.Field>
            </Section.Content>
          </Section>
        );
      }}
    </UniFormMed>
  );
};

const LoadingScreen = ({ formData }) => {
  return (
    <>
      <div className="flex-row space-x-2">
        <Loader inline active size="tiny" />
        <span>Loading click & collect options around {formData?.shipping_address_components?.formatted}</span>
      </div>
    </>
  );
};

export default EMPharmSelector;
