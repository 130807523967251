import React, {useState} from 'react';
import moment from "moment";
import {Button, Card, CardGroup, Icon, Image, Modal} from "semantic-ui-react";
import {useAsyncUpdate} from "xAppLib/Hooks/useAsync";
import API_service from "xAppLib/providers/API_service";
import SimpleShowMore from "xAppLib/UIelems/SimpleShowMore";

function iframeUrl(file) {
	return API_service.API_BASE_URL + `file/doc/view/${file.bct}/${encodeURIComponent(file.img_fn)}`;
}

export function FileGallery({files, onChange, readOnly, itemsPerRow = 4, onFileClickHandler, basic = false}) {
	const [show, setShow] = useState(null);
	const closeModal = () => setShow(null);

	const deletePhoto = useAsyncUpdate(async file => {
		if ('yes' === await app.confirm(`Delete ${file.comn || 'Document'}`, `Are you sure?`)) {
			return await onChange(files.filter(f => f !== file), [file])
		}
	});

	if (!files) {
		return null;
	}
	

	return (
		basic ? 
		<> <div className={`grid grid-cols-${itemsPerRow} gap-4`}>{files.map(file => <div className='relative'>
			<FilePreview file={file}/>
			{!readOnly && !file?.read_only && (
									<Button type="button"
											loading={deletePhoto.loading}
											icon="trash alternate outline"
											basic
											className="absolute right-0 bottom-0 scale-75 origin-bottom-right"
											onClick={() => deletePhoto.fn(file)}/>
								)}	
		</div>)}</div></>
		:
		<>
			<CardGroup stackable doubling itemsPerRow={itemsPerRow}>
				{files.map(file => (
					<Card key={file.img_fn}>
						<Card.Content className="flex items-center justify-center cursor-pointer min-h-28"
									  onClick={() => onFileClickHandler?.(file) || setShow(file)}
						>
							<FilePreview file={file}/>
						</Card.Content>

						<Card.Content extra>
							<div className="flex-horizontal items-center justify-between">
								<div className="flex-vertical text-sm">
									<span><SimpleShowMore content={file.comn} cut_length={50}/></span>
									<span>{formatDate(file.tm)}</span>
								</div>
								{!readOnly && !file?.read_only && (
									<Button type="button"
											loading={deletePhoto.loading}
											icon="trash alternate outline"
											basic
											className="self-end"
											onClick={() => deletePhoto.fn(file)}/>
								)}
							</div>
						</Card.Content>
					</Card>
				))}
			</CardGroup>

			<Modal open={!!show} onClose={closeModal} closeIcon>
				<Modal.Header>
					{show?.comn}
				</Modal.Header>
				<Modal.Content>
					{isAttachment(show)
						? <iframe src={iframeUrl(show)} style={{width: '100%', height: '70vh'}}/>
						: <FilePreview file={show}/>
					}
				</Modal.Content>
				<Modal.Actions>
					<Button basic content="Close" onClick={closeModal}/>
				</Modal.Actions>
			</Modal>
		</>
	);
}

function FilePreview({file}) {
	if (isAttachment(file)) {
		return <Icon name="file pdf outline" size="huge"/>;
	}

	return <Image src={file.url} className="mx-auto"/>
}

function isAttachment(file) {
	return file?.tag === "attachment" || file?.is_attachment
}

function formatDate(file_tm) {
	const time = /^\d{12}$/.test(file_tm)
		? moment(file_tm, "YYMMDDHHmmss")
		: moment(file_tm);

	if (!time.isValid()) {
		return null;
	}

	return time.format("D/M/YY");
}