import React, { useEffect, useMemo } from 'react';
import UniFormMed from 'views/med/UniFormMed';
import { Button, Divider } from 'semantic-ui-react';
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import { useViewport } from 'xAppLib/Hooks';
import logger from 'xAppLib/libs/logger';
import MedSelection from './MedSelection';
import EvermedDeliverySection from './EvermedDeliveryMethods';
import ShippingAddressNew from './ShippingAddressNew';
import EMPharmSelector from './EMPharmSelector';
import PharmacySelect from './PharmacySelect';
import Alert from '../../../NUI/Alert';

const CLICK_AND_COLLECT_STATES = ['VIC', 'QLD', 'NSW', 'WA', 'SA', 'ACT', 'TAS'];

const Fulfilment = (props) => {

  const { Section, formData, discount, medbuy_only, enable_click_and_collect } = props;

    const handleFulfilmentChange = ({uf_this, button}) => {
        const currentSelectedFulfilment = formData.fulfilment
        const newSelectedFulfilment = button.value
        logger.usg_log('Changed fulfilment methods', null, {from: currentSelectedFulfilment, to: button.value})
        uf_this.handleInputChange({target: {name: 'fulfilment', value: newSelectedFulfilment}})
    }

    const enable_delivery = true;
    const enable_cnc = (
        enable_click_and_collect
        && CLICK_AND_COLLECT_STATES.includes(formData?.full_address?.state)
    );
    const enable_escript = !medbuy_only;

    useEffect(() => {
        if (enable_cnc) {
            logger.usg_log('Click & Collect displayed to user')
        }
    }, [enable_cnc]);

    const FULFILMENT_FIELDS = useMemo(
        () => [{
            name: 'fulfilment',
            label: 'How would you like to receive your medication?',
            type: 'text',
            buttons: [
                enable_escript && {
                    label: 'Electronic Script',
                    value: 'escript',
                    desc: 'An electronic prescription will be sent to you via email and SMS.',
                },
                enable_cnc && {
                    label: 'Click & Collect',
                    value: 'click_collect',
                    desc: 'Skip the queue and collect your medication from a pharmacy near you.',
                },
                enable_delivery && {
                    label: 'Deliver to me',
                    value: 'delivery',
                    desc: 'We will arrange delivery from a pharmacy to your nominated address.',
                },
            ].filter(Boolean),
        }],
        [enable_escript, enable_cnc]
    );

    return (
        <UniFormMed {...props} section="fulfilment" fields={FULFILMENT_FIELDS}>
            {(values, valids, uf_this, fields) => {
                return (
                    <Section>
                        <Section.Header>How would you like to receive your medication?</Section.Header>
                        <Section.Content>
                            {fields.fulfilment.buttons.length > 1 && (<>
                                <div className="flex-column space-y-4">
                                    <Button.Group widths={fields.fulfilment.buttons.length}
                                                  fluid
                                                  color={valids && !valids.fulfilment && 'red'}>
                                        {fields.fulfilment.buttons.map((button) => (
                                            <Button
                                                key={button.value}
                                                type="button"
                                                size="medium"
                                                data-testid="radio-delivery"
                                                basic={formData.fulfilment != button.value}
                                                color={formData.fulfilment == button.value ? 'blue' : 'grey'}
                                                primary={formData.fulfilment == button.value}
                                                onClick={() => handleFulfilmentChange({uf_this, button})}
                                            >
                                                {button.label}
                                            </Button>
                                        ))}
                                    </Button.Group>
                                </div>
                                <FulfilmentDescription {...{
                                    methods: fields.fulfilment.buttons,
                                    selectedMethod: formData.fulfilment
                                }} />
                                {!!formData.fulfilment && <Divider/>}
                            </>)}
                            <div>
                                {enable_escript && formData.fulfilment === 'escript' && <EscriptSection {...props} />}
                                {enable_delivery && formData.fulfilment === 'delivery' && <DeliverySection {...props} discount={discount} />}
                                {enable_cnc && formData.fulfilment === 'click_collect' && <ClickAndCollectSection {...props} />}
                            </div>
                        </Section.Content>
                    </Section>
                );
            }}
        </UniFormMed>
    );
};

const FulfilmentDescription = ({methods, selectedMethod}) => {
    const {width} = useViewport();

    //
    const minimumWidthPerOption = 255;
    const smallScreenWidthBreakpoint = minimumWidthPerOption * (methods?.length || 2);

    if (smallScreenWidthBreakpoint < width) {
        return (
            <div className={`grid grid-cols-${methods.length} justify-items-center divide-x mt-3`}>
                {methods?.map((method) => (
                    <span key={method.value} className="text-gray-600 text-center px-4"
                          dangerouslySetInnerHTML={{__html: method.desc}}/>
                ))}
            </div>
        );
    }
    if (selectedMethod && width <= smallScreenWidthBreakpoint) {
        return <p className="text-gray-600 mt-3"
                  dangerouslySetInnerHTML={{__html: methods.find((method) => method.value === selectedMethod)?.desc}}/>;
    }
    return <></>
};

const EscriptSection = (props) => {
    return <PharmacySelect inline {...props} />;
};

const DeliverySection = (props) => {
    const hide_form_body = !props.formData.shipping_address;
    const sharps = !!(props.med_data?.xtra?.sharps);

    const {medRes, loading} = props?.evermed ?? {};

	const hasMedError = !loading && medRes !== 'ok';

	if (hasMedError) {
		return <MedicationNotAvailable {...props} />;
	}

    return (
        <>
            <ShippingAddressNew inline {...props} />
            {hide_form_body && (
                <p className='mt-4'>Please enter your shipping address so we can find the best options for you.</p>
            )}
            {sharps && (<>
                <div className='mt-4'/>
                <SharpsSection inline {...props} />
            </>)}
            <div className='mt-4'/>
            <MedSelection inline {...props} hide_section={hide_form_body}/>
            <div className='mt-4'/>
            <EvermedDeliverySection inline {...props} hide_section={hide_form_body}/>
        </>
    );
};

const SHARPS_FIELDS = [{
    name: 'sharps',
    label: 'Do you need a sharps bin?',
    type: 'radio',
    options: [
        {c: 'yes', n: 'I require a sharps disposal bin to be sent to me'},
        {c: 'no', n: 'I already have a sharps disposal bin'},
    ],
}];

const SharpsSection = props => {
    const {Section} = props;
    return (
        <UniFormMed {...props} section="sharps_selection" fields={SHARPS_FIELDS}>
            {(values, valids, uf_this, fields) => (
                <Section>
                    <Section.Header className="m-0 mb-3 text-lg">Sharps Disposal Bin</Section.Header>
                    <Section.Content className="p-0">
                        <UniFieldSUI fl={fields.sharps} {...{values, valids, uf_this}} show_label={false}/>
                    </Section.Content>
                </Section>
            )}
        </UniFormMed>
    );
}

const ClickAndCollectSection = (props) => {

    const { medRes, clickRes, loading } = props?.evermed ?? {};

	const hasMedError = !loading && medRes !== 'ok';

	if (hasMedError) {
		return <MedicationNotAvailable {...props} />;
	}

    return (
        <>
            {clickRes !== 'err' && (
                <>
                    <MedSelection inline {...props} />
                    <Divider/>
                </>
            )}
            <EMPharmSelector inline {...props} />
        </>
    );
};

const MedicationNotAvailable = (props) => {
	const { medErrRsn } = props?.evermed ?? {};

	const msg = medErrRsn || 'An unexpected error has occured. Please contact InstantScripts support for assistance';

	return <Alert error header='Medication not available for Delivery or Click & Collect' content={<p>{msg}</p>} />;
};

export default Fulfilment;
