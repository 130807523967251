import React, { useState } from "react";
import {
    Button,
    Container,
    Form,
    Grid,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
} from "semantic-ui-react";
import useOnSettingsButtonClick from "../../utlis/hooks/useOnSettingsButtonClick";
import reducer from "./reducer";
import { COMPONENTS_MAP, INIT_MAP, MAIN_COMPONENTS } from "./components/index";
import Title from "../components/Title";
import InlineController from "./InlineController";

const INITIAL_STATE = {
    aside: [],
    main: [],
};

const DEV = false;

const InfoSegment = ({
    data,
    onDataChange,
    readOnly,
    settingsWrapper,
    viewerOnly,
}) => {
    const [toggleSettings, setToggleSettings] = useState(true);
    const [state, dispatch] = React.useReducer(
        (prev, action) => reducer({ state: prev, action, onDataChange }),
        {
            ...(Object.keys(data).length ? data : INITIAL_STATE),
        }
    );
    const { main, aside } = state;
    const [confirm, setConfirm] = useState(null);

    !viewerOnly &&
        useOnSettingsButtonClick(settingsWrapper, "settings", () =>
            setToggleSettings((prev) => !prev)
        );

    const border =
        DEV && toggleSettings && !readOnly ? "1px solid grey" : "none";

    const editing = confirm && state[confirm.col][confirm.order];
    const EditingComponent = confirm && COMPONENTS_MAP[editing?.component];

    const onConfirm = () => {
        const formElement = document.getElementById("edit-component-form");
        const formData = new FormData(formElement);

        const update = {};
        for (let key in INIT_MAP[editing.component]) {
            const input = formData.get(key);
            update[key] = key.startsWith("data")
                ? (input && JSON.parse(input)) || []
                : input;
        }

        dispatch({
            type: `UPDATE`,
            order: confirm.order,
            target: confirm.col,
            update,
        });

        setConfirm(null);
    };

    const mains = main
        .sort((a, b) => a.order - b.order)
        .map((panel, index) => {
            const Component = COMPONENTS_MAP[panel.component];

            if (!Component) return null;

            return (
                <Grid.Row key={index}>
                    <InlineController
                        visible={toggleSettings && !readOnly}
                        order={panel.order}
                        target="main"
                        dispatch={dispatch}
                        first={index !== 0}
                        last={index !== main.length - 1}
                    />
                    <Container
                        onClick={
                            toggleSettings && !readOnly
                                ? (e) => {
                                      e.stopPropagation();
                                      setConfirm({
                                          col: "main",
                                          order: panel.order,
                                      });
                                  }
                                : undefined
                        }
                    >
                        <Component key={index} {...panel} dispatch={dispatch} />
                    </Container>
                </Grid.Row>
            );
        });

    const asides = aside
        .sort((a, b) => a.order - b.order)
        .map((panel, index) => {
            const Component = COMPONENTS_MAP[panel.component];

            if (!Component) return null;
            return (
                <Grid.Row key={index}>
                    <InlineController
                        visible={toggleSettings && !readOnly}
                        order={panel.order}
                        target="aside"
                        dispatch={dispatch}
                        first={index !== 0}
                        last={index !== main.length - 1}
                    />
                    <Container
                        onClick={
                            toggleSettings && !readOnly
                                ? (e) => {
                                      e.stopPropagation();
                                      setConfirm({
                                          col: "aside",
                                          order: panel.order,
                                      });
                                  }
                                : undefined
                        }
                    >
                        <Component {...panel} />
                    </Container>
                    <br />
                </Grid.Row>
            );
        });

    const modalEl = confirm && (
        <Modal
            style={{ height: "fit-content", margin: "auto" }}
            onClose={() => setConfirm(null)}
            open={!!confirm}
        >
            <Form id="edit-component-form">
                <ModalHeader className="m-4">
                    <Title title={`Editing ${editing.component} component`} />
                </ModalHeader>
                <ModalContent className="m-4">
                    <EditingComponent {...editing} editMode />
                </ModalContent>
                <ModalActions className="m-4">
                    <Button content="Cancel" onClick={() => setConfirm(null)} />
                    <Button content="Confirm" onClick={onConfirm} />
                </ModalActions>
            </Form>
        </Modal>
    );

    return (
        <>
            <Container className="landing-page-segment mt-4">
                <Grid stackable style={{ border, margin: 0 }}>
                    <Grid.Row>
                        <Grid.Column style={{ border }} width={10}>
                            <Grid>{mains}</Grid>
                        </Grid.Column>
                        <Grid.Column width={1} />
                        <Grid.Column style={{ border }} width={5}>
                            <Grid>
                                <Container style={{ padding: 0 }}>
                                    {asides}
                                </Container>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            {toggleSettings && !readOnly && (
                <Container className="mt-10">
                    <Grid stackable style={{ border, margin: 0 }}>
                        <Grid.Row>
                            <Grid.Column style={{ border }} width={11}>
                                {MAIN_COMPONENTS.map((item, index) => (
                                    <Button
                                        style={{ marginRight: "8px" }}
                                        key={index}
                                        onClick={() =>
                                            dispatch({
                                                type: "ADD",
                                                component: item.name,
                                                target: "main",
                                            })
                                        }
                                    >
                                        {item.label}
                                    </Button>
                                ))}
                            </Grid.Column>
                            <Grid.Column style={{ border }} width={5}>
                                <Button
                                    style={{ marginRight: "8px" }}
                                    onClick={() =>
                                        dispatch({
                                            type: "ADD",
                                            component: "help",
                                            target: "aside",
                                        })
                                    }
                                >
                                    Add Card
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {modalEl}
                </Container>
            )}
        </>
    );
};

export default InfoSegment;
