import React, { Component } from 'react';
import { Button, Icon, Header, List, Checkbox, Segment, Popup } from 'semantic-ui-react'
import API_service from 'xAppLib/providers/API_service'
import debounce from 'xAppLib/libs/debounce'
import AddressSearchNew from 'xAppLib/UIelems/AddressSearchNew'
import logger from 'xAppLib/libs/logger'
import moment from 'moment';
import Section from '../med/Section';
import {cls} from "views/NUI/utils";

const DEBUG = false
// number of search results to show per page
const PAGE_SIZE = 3
// Max number of times a user can click 'show more' button
const MAX_PAGE_LIMIT = 20
// Priceline promotion options
const PROMOTE_PRICELINE = true
const MAX_PROMOTED_PRICELINE_DISTANCE = 5000 // Meters
const MAX_PROMOTED_RESULTS = 3

const notfound_pharm = adr => ({
			name: `We will find a pharmacy near ${adr || 'you'} and let you know.`,
			address:"",
			oid:'RbsKz9o'
		})

// JS has Sunday at index 0, while Google has Monday.
// So, let's change Sunday to be index 6 and shift everything else back.
const now = new Date();
const jsDayIndex = now.getDay() - 1;
const todayDayIndex = jsDayIndex < 0 ? 6 : jsDayIndex;
const tomorrowDayIndex = todayDayIndex+1 === 7 ? 0 : todayDayIndex+1;

let get_pharms

const convert24HourTime = (time24) => {
	if (!time24)	return 'n/a';

	const hour = +time24.substr(0, 2);
	const hourDisplay = (hour % 12) || 12;
	const minutes = time24.substr(2, 3);
	const minsDisplay = minutes === '00' ? '' : ':' + minutes;
	const amPm = hour < 12 ? 'am' : 'pm';

	return `${hourDisplay}${minsDisplay}${amPm}`;
};

const enable_notfound_pharm = true
class SearchPharm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSearch:false,
			options:[],
			oid:props.oid,
			latitude:null,
			longitude:null,
			loading:true,
			page: 1,
			pharmName:'',
			limit: 5,
			loadMoreBtnEnabled: true,
			searchField:props.init_addr||'',
			searchingLocation:false,
			isAddressChanged:false
		};
		
		this.debouncedOnSearchAddress = debounce(this.onSearchAddress,500)

		SearchPharm.load_pharms();
	}

	static load_pharms() {
		if (!get_pharms || get_pharms?.error)
			get_pharms = new Promise(resolve=>API_service.load_data('get-map', {pharm_search:'y'}).then(resolve).catch(()=>get_pharms.error=true))
	}

	async componentDidMount() {
		await this.get_pharmacies()

		this.doSearch(this.state.searchField)

		this.props.oid && this.setPharm(this.props.oid)
	}
	
	componentWillUnmount() {
		this.goneAway = true
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.props.oid && this.props.oid != prevProps.oid) {
			this.setPharm(this.props.oid)
		}

		if (this.props.init_addr && this.props.init_addr != prevProps.init_addr) {
			this.setState({searchField:this.props.init_addr})
			this.doSearch(this.props.init_addr)
		}
	}
	
	
	async get_pharmacies() {
		const pharms = await get_pharms
		const pharm = pharms && pharms.filter(p=>p.oid == this.state.oid).pop()
		const pharmName = pharm && <span>{pharm.name}<br/>{pharm.address}</span> || "?"
		
		if (this.goneAway)
			return
			
		try {
			this.setState({
				loading:false,
				pharms,
				showSearch: !(pharm&&pharm.name) && !this.props.defaultPharm,
			})
		} catch (e) {
			DEBUG && console.log(e);
		} 
	}

	sort_options(options) {

		let sortedOptions
		// Filter out undefined options and sort by closest distance and remote promoted status
		sortedOptions = options
			.filter(option => option.meters !== undefined)
			.map(opt => ({...opt, promoted: false}))
			.sort((p1, p2) => p1.meters - p2.meters || p1.name.localeCompare(p2.name));

		if(PROMOTE_PRICELINE){
			// Find a priceline within MAX_PROMOTED_PRICELINE_DISTANCE to promote
			let pricelinesToPromote = sortedOptions.filter(
				pharm => pharm.meters <= MAX_PROMOTED_PRICELINE_DISTANCE && pharm.name.match(/(priceline)/i)
			).slice(0, MAX_PROMOTED_RESULTS);
			if(pricelinesToPromote.length > 0){
				sortedOptions = [
					...pricelinesToPromote.map(p => ({...p, promoted: sortedOptions.findIndex(o => o.oid === p.oid) !== 0 })),
					...sortedOptions.filter(item => !pricelinesToPromote.map(p => p.oid).includes(item.oid))
				];
			}
		}

		return sortedOptions
	}
	
	derive_options(pharms, page) {
		const from = 0;
		const to = PAGE_SIZE * page;
		const filtered = pharms.slice(from, to);

		this.setState({
			// update the search radius displayed to the user with the maximum distance in the results
			limit: Math.ceil(Math.max(...filtered.map(o => o.meters)) / 1000),
			loadMoreBtnEnabled: page < MAX_PAGE_LIMIT && to < pharms.length
		});

		return filtered
	}
	
	onSearchAddress = async (value) => {
		DEBUG && console.log("onSearchAddress(%o)",value);
		if (value.length < 5)
			return
		this.setState({loadingResults:true})
		logger.usg_log('onSearchAddress_FE', location.pathname, null, {value:value})
		const result = await this.geocode(value + (value.toLowerCase().indexOf('australia')===-1 && ' australia' || '') )
		DEBUG && console.log("onSearchAddress result ",result);
		if (result) {
			this.setState({latitude:result.lat,longitude:result.lng,limit:5, geoaddr:result.geoaddr, address:value})
			this.calculate_distance(result.lat,result.lng,5)
			this.props.defaultPharm && !this.state.showSearch && this.setPharm(this.state.options[0]?.oid)
		}
		this.setState({loadingResults:false})
		
	}
	
	async get_user_location() {
		DEBUG && console.log("get_user_location()");
		this.setState({searchField:'',searchingLocation:true})
		const {latitude,longitude}  = await new Promise((resolve,reject)=>{
			navigator.geolocation && navigator.geolocation.getCurrentPosition((position) => {
				DEBUG && console.log("got ",position);
				const {latitude,longitude} = position.coords
				resolve(position.coords)
			},(error)=>{
				alert("Could not detect your location.")
				resolve({latitude:null,longitude:null})
			});
		})
		
		if (latitude) {
			logger.usg_log('get_user_location_FE', location.pathname, null, {latitude:latitude, longitude:longitude})
			const address = await this.reverse_geocode({latitude,longitude})
			DEBUG && console.log("address=",address);
			if (address) {
				DEBUG && console.log("searchField=",address.formatted_address);
				this.setState({searchField:address.formatted_address,address:address.formatted_address,geoaddr:address,latitude,longitude,limit:5})
				this.calculate_distance(latitude,longitude,5)
			}
		}
		
		this.setState({searchingLocation:false})
	}
	
	// https://stackoverflow.com/a/365853/192705
	degreesToRadians(degrees) {
	  return degrees * Math.PI / 180;
	}

	distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
	  var earthRadiusKm = 6371;

	  var dLat = this.degreesToRadians(lat2-lat1);
	  var dLon = this.degreesToRadians(lon2-lon1);

	  lat1 = this.degreesToRadians(lat1);
	  lat2 = this.degreesToRadians(lat2);

	  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
	          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
	  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
	  return earthRadiusKm * c;
	}

	async toggle_options() {
		try {
			let { showSearch } = this.state
			this.setState({showSearch:!showSearch})

			if (!this.state.options?.length && this.state.searchField) {
				this.doSearch(this.state.searchField);
			}

		} catch (e) {
			DEBUG && console.log(e);
		} 
	}
	
	calculate_distance(latitude,longitude,limit) {
		DEBUG && console.log("calculate_distance()",latitude,longitude,limit);
		const pharms = this.state.pharms?.map(p=>{
			const {lat,lng} = p.geo&&p.geo.geometry&&p.geo.geometry.location || {}
			const meters = lat ? Math.round(this.distanceInKmBetweenEarthCoordinates(latitude,longitude,lat,lng)*1000) : 9999000
			// DEBUG && console.log("%o (%o) => %o => %o",p.name,p.address,{lat,lng},km)
			return {...p, meters}
		})

		if (!pharms)
			return 

		const sortedOptions = this.sort_options(pharms);
		
		// Start with some default values on each new search
		this.setState({
			page: 1,
			pharms: sortedOptions,
			options: this.derive_options(sortedOptions, 1)
		})
	}
	
	expand_limit() {
		const new_page = this.state.page + 2;

		this.setState({
			page: new_page,
			options: this.derive_options(this.state.pharms, new_page)
		});
	}
	
	setPharm = (oid, index = 0) => {
		this.setState({ oid, showSearch:false })
		const pharm = oid == notfound_pharm().oid 
					? {...notfound_pharm(this.state.geoaddr?.formatted_address || this.state.searchField), address: this.state.geoaddr?.formatted_address || this.state.searchField} 
					: this.state.pharms && this.state.pharms.filter(p=>p.oid == oid).pop()
		this.props.onSelect && this.props.onSelect(oid, pharm?.name, pharm?.address, this.state.geoaddr?.formatted_address || this.state.searchField, pharm)
		index > 0 && logger.usg_log('pharm_loc_inlist_FE', location.pathname, 'Selected pharmacy index in the list' , {index: index})
	}
	
	onSearchChange = ({ formatted = '', geo }) => {
		DEBUG && console.log("onSearchChange(%o)",formatted,geo);
		this.setState({searchField:formatted})
		if (!geo) {
		this.debouncedOnSearchAddress(formatted)
		} else {
			!this.state.isAddressChanged && logger.usg_log('pharm_address_change_FE', location.pathname, 'Address is changed')
			const result = geo.geometry.location
			this.setState({latitude:result.lat,longitude:result.lng,limit:5, geoaddr:geo, address:formatted, isAddressChanged: true})
			this.calculate_distance(result.lat,result.lng,5)
		}
	}
	
	async geocode(address) {
		DEBUG && console.log("geocode(%o)",address);
		logger.usg_log('geocode_FE', location.pathname, null, {address:address})
		const result = await API_service.load_data('geocode', {address})
		DEBUG && console.log("geocode result ",result);
		
		if (!result || result.error) {
			return null
		} else {
			const {lat,lng} = result.geometry&&result.geometry.location
			return {lat,lng, geoaddr:result}
		}
	}

	doSearch = (search) => {
		this.onSearchAddress(search)
	}

	render() {
		const { placeholder = 'Select Pharmacy', noChange, defaultPharm, error } = this.props
		const { loading, showSearch, oid, pharms } = this.state

		if (loading) {
			return <div>
						<Icon loading name='spinner' />
					</div>
		}
		const pharm = oid == notfound_pharm().oid ? notfound_pharm(this.state.geoaddr?.formatted_address || this.state.searchField) : pharms && pharms.filter(p=>p.oid == oid).pop()
		const pharmName = pharm && <span><strong>{pharm.name}</strong><br/>{pharm.address}</span> || (this.props.error && "No Pharmacy Selected") || ''
		const showSelected = (defaultPharm && !showSearch && oid) || !defaultPharm
		
		return <React.Fragment>
				{showSearch && this.renderSearch()}
				{showSelected && <div className={cls("flex field gap-2", (pharmName || oid) && " border border-is-gray-50 rounded-lg p-4", error && " error")}>
						<div style={{flexGrow:1}}>
							<label>
								{pharmName || oid }
							</label>
						</div>
						{!noChange && !showSearch && <Button basic className=" mt-4 max-h-[44px]" type="button" data-testid="button-change-pharmacy" onClick={_=>this.toggle_options()}>Change</Button>}
				</div>}
			</React.Fragment>
	}
	
	renderSearch() {
		const { not_local } = this.props
		const { intro = <p style={{marginBottom:'.5em'}}><small>Type in your address below to search for nearby pharmacies or use the <Icon name='location arrow'/> button to use your current location.</small></p> } = this.props
		const { latitude, longitude, limit,searchField,searchingLocation } = this.state
		return <React.Fragment>
			 {/*intro*/}

				<AddressSearchNew 
					showLocate={!not_local} 
					value={{ formatted: searchField }}
					field={{ placeholder: 'Type address to search...', title: 'Enter your address:' }}
					onChange={this.onSearchChange}
					allowLocalArea
					/>
			
			{this.renderOptions()}
			
		</React.Fragment>
	}

	displayMetersInKms(meters) {
		const kms = (Math.round((meters/1000) * 100) / 100).toFixed(1)
		return `${kms} ${kms < 1 ? 'kms' : 'km'}`
	}

	getOpenHours(openingHours, dayIndex) {
		if (openingHours.periods[dayIndex]) {
			const period = openingHours.periods[dayIndex];
			return `${convert24HourTime(period.open?.time)} - ${convert24HourTime(period.close?.time)}`;
		}

		return null;
	}

	renderOpeningTimes(openingHours) {
		if (!openingHours.periods || !openingHours.periods.length) return null;

		const todayOpen = this.getOpenHours(openingHours, todayDayIndex)?.split(" - ")[0];
		const todayClose = this.getOpenHours(openingHours, todayDayIndex)?.split(" - ")[1];
        const tomorrow = this.getOpenHours(openingHours, tomorrowDayIndex)?.split(" - ")[0];
        
        const dayIndex = moment().day() > 0 ? moment().day() - 1 : 6;
        const nowTime = moment().hours() * 100 + moment().minutes();

        const openTime = parseInt(openingHours.periods[dayIndex]?.open?.time);
        const closeTime = parseInt(openingHours.periods[dayIndex]?.close?.time);
        const isOpen = openTime ? (nowTime >= openTime && nowTime <= closeTime) : false;

        return (
			<React.Fragment>
				<p className="mt-4">
					{isOpen ? 
					<span className="px-2.5 py-0.5 text-green-800 bg-green-100 rounded-3xl">Open</span> : 
					<span className="px-2.5 py-0.5 text-red-800 bg-red-100 rounded-3xl">Closed</span>}
					<span className="w-2 h-2 bg-gray-300 rounded-full inline-block mx-3" />
					<span>{isOpen ? `Closes ${todayClose}` : 
					((nowTime < openTime) ? `Opens today ${todayOpen}` : 
					(tomorrow ? `Opens tomorrow ${tomorrow}` : 'Closes tomorrow'))}</span>
				
				<Popup position='top center' trigger={<Icon size="small" style={{marginLeft:'5px'}} name="question circle outline" color="blue" />}>
					<Popup.Header>Pharmacy open hours</Popup.Header>
					<Popup.Content>Pharmacy open hours are subject to change without notice - especially during holiday periods.<br/><br/>It is recommended that patients double check their selected pharmacy's open hours before placing their order by calling the pharmacy or visiting the pharmacy's website.</Popup.Content>
				</Popup>
				</p>
			</React.Fragment>

		);
	}
	
	renderOptions() {
		const { latitude, longitude, limit, oid, options, pharms, geoaddr, searchField, loadMoreBtnEnabled } = this.state
		if (!options || options?.length == 0 )
			return null

		const img_path = "https://storage.googleapis.com/instant-med-public/icons/";
			
		const { optionHeader = name => (
			<div className='flex items-center space-x-2 text-left'>
				{name.match(new RegExp(/(priceline)/i)) ?
				<img style={{ height: 32 }} src="https://www.priceline.com.au/assets/images/favicon.ico" />
				: ''
				}
				<p className="font-bold align-middle">{name}</p>
			</div>
		)} = this.props
		
		const no_pharm_options = <List.Item key={notfound_pharm().oid} onClick={_=>this.setPharm(notfound_pharm().oid)}>
			<Checkbox checked={oid==notfound_pharm().oid} 
				radio 
				label={<label style={{}}>
				{optionHeader(`Can't find pharmacy near ${this.state.geoaddr && this.state.geoaddr.formatted_address || this.state.searchField || 'you'}?`)}
				<p>Select this option and we will find one for you.</p>
			
			</label>} /> 
				
		</List.Item>

		return <>
			<p className='mt-4'>Showing {options.length} {options.length == 1 ? 'pharmacy':'pharmacies'} within {limit}km radius from {this.state.address || ' location'}.</p>
			{/*<p className='mt-4'>Showing the nearest pharmacy to your primary address</p>*/}
			<Segment basic loading={!!this.state.loadingResults} style={{padding:0}} >
				<div className='border mb-4 rounded rounded-lg border-gray-200 overflow-hidden'>
			<List divided relaxed >
				{ enable_notfound_pharm && (options.length==0 || options.length && options.filter(pharm => pharm.meters<=3000).length == 0) && no_pharm_options }
				{options.map((o, index)=><List.Item key={o.oid} className={oid === o.oid ? "bg-blue-100":"bg-transparent"}>
				<Checkbox 
						onClick={_=>this.setPharm(o.oid, index+1)} 
						checked={oid==o.oid} 
						radio
						data-testid="radio-pharmacy" 
						label={<label style={{}}>
						{optionHeader(o.name)}
						
							<div className="flex flex-row gap-3 mt-2"> 
								<img src={img_path+"icon-address.svg"} alt="address" />
									<p>{o.address} <em>– {this.displayMetersInKms(o.meters)}</em>
									<a href={`https://www.google.com/maps/search/?api=1&query=${o.name} ${o.address}`} onClick={e=>e.stopPropagation()} className='ml-2 text-blue-600'  target="_blank"> 
										<Icon name="external"/> 
									</a> 
								</p>
							</div>

							<div className="flex flex-row gap-3 mt-2"> 
								<img src={img_path+"icon-phone.svg"} alt="phone" />
								<p>{o.phone} 
									<a href={`tel:${o.phone}`} onClick={e=>e.stopPropagation()} className='ml-2 text-blue-600'>
										<Icon name="external"/> 
									</a>
								</p>
							</div>
							<div className='flex space-x-2 pt-2'>
								{ o.geo?.opening_hours ? this.renderOpeningTimes(o.geo.opening_hours) : '' }
								{ o.promoted ? <p className='text-gray-500'>Promoted</p> : '' }
							</div>
						</label>} />

				
				  	
				</List.Item>)}
				{/* enable_notfound_pharm && (options.length===0 || (options[0].km>1 && options[0].km<5 )) && no_pharm_options */}
				
			  </List>
			  </div>
			  { loadMoreBtnEnabled && <Button size="small" color="blue" fluid type="button" inverted onClick={_=>this.expand_limit()}>Load more pharmacies</Button> }
			  </Segment>
		</>
	}
}

export default SearchPharm;
