import React, {Component} from "react";
import {Modal, Button, Icon, Container,Message,Form, Header} from "semantic-ui-react";
import UniForm from "../UniForm/UniForm";
import UniFormSUI from "../UniForm/UniForm-SUI";
import support_model from "models/support_model";
import {Tel} from "../../views/UIelems/Tel";

class SupportComplication extends Component {
	state = {sending:false,sent_res:null,values:{}};

	render() {
		const {sent_res} = this.state;
		return (
				<Modal.Content>
					{sent_res === 'ok' ? this.render_ok() : this.render_form()}
				</Modal.Content>
		);
	}
	
	render_ok() {
		return <React.Fragment>
					<Message
						positive
						header='Success'>
						Your medical emergency request has been sent. 
						<h2>Please call {app.site_status.cosm_emergency_nm} now on <Tel value={app.site_status.cosm_emergency_ph} />.</h2>
					</Message>
				  	<Button fluid color="green" size='large' onClick={this.props.onClose} content="Close" />
			</React.Fragment>
	}
	
	render_form() {
		const {sent_res,sending,values} = this.state;
		return <UniForm
			onActForm={ async d => {
						this.setState({sending:true, sent_res:null, error:null})

						const result = await support_model.send_cosm_med_emerg(d)
						const result2 = await support_model.send_request(d)

						this.setState({ sent_res: result.res, error:result.err, sending:false })
					} }
			render_children={true}
			debug={true}
			fields={support_model.DET_FIELDS_COMPLICATION}
			// onUpdate={values=>{this.setState({values})}}
			// defaultValues={values}
			defaultValues={{
							name: app.user.user_det?.displayName || '',
							phone: app.user.prof?.mobile || '',
						}}
		>
			{(values, valids, uf_this) => {
				return (
					<React.Fragment>
					
						{sent_res == 'err' && <Message
									warning
									header='Problem'
									content='There was a problem sending your request. Please try again.'
								  />}
					  {sent_res != 'err' && <div>
					  				<Header color='red'>Caution</Header>
									<h2 className='text red'>Use this form only for medical emergencies. 
										Please use the tech support form for any other problems.</h2>
									<br/>
								  </div>}

						<Message icon="warning circle" content={<>
							Please ensure your client has their Medicare Card available when contacting our doctors to
							discuss an adverse reaction. Failure to supply a Medicare number can impact the ability to
							provide a prescription as quickly as possible.
						</>} />
						<UniFormSUI
							fields={support_model.DET_FIELDS_COMPLICATION}
							values={values}
							valids={valids}
							uf_this={uf_this}
							loading={sending}
							show="fields_form"
							size={'large'}
							btns={
								<Button fluid color="green" size='large'  onClick={d => uf_this.actForm(d)} content="Submit" />
							}
						/>
					
					</React.Fragment>
				);
			}}
		</UniForm>
	}
}

export default SupportComplication;
