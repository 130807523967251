
import React, { useState,  useRef, useEffect } from 'react';
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { cls as clsn } from 'views/NUI/utils'
import { Link } from "react-router-dom";
import menu_model from 'models/menu_model'
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';

// Side Section
const intro = {
    about: { 
                title: 'About Us', 
                desc: `Founded in Melbourne in 2018, InstantScripts has helped hundreds of thousands of Australians access health and medical 
                services from the comfort of their homes.`
            },
    certs: { 
                title: 'Medical Certificates', 
                desc: `Do you need to call in sick or take a day off for carer's leave? Our online medical
				certificate service is quick, easy and convenient. Medical certificates are certified by
				Australian-registered doctors. Choose from four types of certificates.`
            },
    telehealth:   { 
                title: 'Telehealth Consultations', 
                desc: `If you need to speak to a doctor about a health concern we can help. Our
				Australian-registered doctors are available from anywhere in Australia, every day of the
				year, 6am to midnight.`
            },
    scripts:    { 
                title: 'Scripts', 
								desc: `Fill out a digital consultation form and, if approved by one of our Australian-registered doctors, we will email or text you an eScript or you can have the
											medication delivered. Patients may need to speak to a doctor to review their medical history.` 
							},
    do:     { 
                title: 'What we do', 
                desc: `InstantScripts is Australia's leading online medical clinic. We offer a range of services 
                including online scripts and telehealth consultations anywhere in Australia, every day, from 6am to midnight.`
            },
    treat: { 
                title: 'What we treat', 
                desc: `InstantScripts provides personalised care to Australians for a range of health conditions. 
				Australians can access online prescriptions for over 300 medications if approved by our doctors.`
            }
}

const intro_links = {
	treat: [{ title: 'Need a script for your medication?', link_text: 'Request a Script', link: '/m/pharm/online/prescription' }, ],
	do: [{ title: 'Not sure where to start?', link_text: 'View Services', link: '/m/pharm/online/prescription' }],
	scripts: [{ title: 'Need a script for your medication?', link_text: 'Request a Script', link: '/m/pharm/online/prescription' }],
    certs: [{ title: 'Do you need a medical certificate?', link_text: 'Request a Certificate', link: '/c/pharm/certs/' }],
	telehealth: [{ title: 'Need to speak to a doctor?', link_text: 'Request a Consultation', link: '/cat/DocCons' }],
}

// Main Section
const tiles = {
	about: [
		{ title: 'Our story', desc: 'Find out more about Australia\'s leading online medical clinic.', link: '/about-us' },
		{ title: 'FAQ', desc: 'Get answers to our patients\' most frequently asked questions.', link: '/faq' },
		{ title: 'Help centre', desc: 'Access help and support on our service from our local team.', link: 'https://help.instantscripts.com.au/en/' },
		{ title: 'Careers', desc: 'Are you a doctor who would like to work with us? Find out more.', link: '/careers', },
		{ title: 'News', desc: 'Get the latest health and medical news in Australia.', link: 'https://www.instantscripts.com.au/blog' },
		{ title: 'Privacy', desc: 'Read our privacy policy and understand our security protocol.', link: '/privacy' },
		{ title: 'Contact us', desc: 'Contact our friendly team who are always more than happy to help.', link: '/contact-us' },
		{ title: 'For Pharmacies', desc: 'See how our service can help your customers access prescriptions.', link: '/for-pharmacies' },
		{ title: 'OnePass', desc: 'See how OnePass members enjoy exclusive benefits.', link: '/onepass' },
		{ title: 'In the media', desc: 'See the latest media releases from InstantScripts.', link: 'https://www.instantscripts.com.au/media_release/' },
		{ title: 'InstantCosmetics', desc: 'Supporting cosmetic nurses with access to quality cosmetic doctors.', link: 'https://www.instantcosmetics.com.au/' },
		{ title: 'Refer a friend', desc: 'Introduce your friends to InstantScripts and get rewarded.* Terms and conditions apply.', link: '/refer-a-friend' },
	],
	do: [
		{ title: 'Online prescriptions', desc: 'Access scripts for over 300 medications.', link: '/m/pharm/online/prescription' },
		{ title: 'Telehealth consultations', desc: 'Speak to an Australian-registered doctor when you need to.', link: '/cat/DocCons' },
		{ title: 'Medical certificates', desc: 'Access certificates for work, uni or carer\'s leave.', link: '/c/pharm/certs/' },
		// { title: 'Medicine delivery', desc: 'Select from express or postal delivery.', link: '/m/pharm/online/prescription' },
		{ title: 'Treatment Plans', desc: 'Access repeat prescriptions and medication home delivery for some conditions.', link: 'https://www.instantscripts.com.au/treatment-plans/', new: true },
		{ title: 'Blood tests', desc: 'Access a range of blood test request forms.', link: '/c/pharm/patho' },
		{ title: 'Specialist referrals', desc: 'Request a referral for skin checks, eye tests and gastro.', link: '/c/pharm/express-specialist-referrals' },
		/*{ title: 'Specialist consultation', desc: 'At elementum arcu tellus ac sempla praesent vestibulum', link: '/m/pharm/online/prescription' },*/
		{ title: 'Weight Loss Treatments', desc: 'Access medical treatments for weight loss.', link: '/weight-loss/' },
		{ title: 'Mental health consultations', desc: 'Find out about the mental health services we provide.', link: '/mental-health-services/' },
		{ title: 'COVID-19', desc: 'Request a COVID-19 antiviral consultation to assess your eligibility.', link: '/covid-antivirals-1/' },
	]
}

const image_links = {
	scripts:  [
		{
			img:'https://storage.googleapis.com/instant-med-public/megamenu/is-online-scripts.jpg',
			alt: 'person holding phone',
			title: 'Online Prescriptions',
			desc: 'Find out how our online scripting service works.',
			link: '/online-scripts',
			link_text: 'Find out more',
		},
		{
			img: 'https://storage.googleapis.com/instant-med-public/megamenu/girl-on-phone-with-medicine.jpg',
			alt: 'girl on phone with medicine',
			title: 'Find the medication you need',
			desc: 'Access scripts for over 300 medications from our catalogue.',
			link: '/m/pharm/online/prescription',
			link_text: 'Request a Script',
		},
		{
			img: 'https://storage.googleapis.com/instant-med-public/megamenu/woman-ordering-medication-online.jpg',
			alt: 'woman ordering medication online',
			title: 'Medication delivered to your door',
			desc: 'Our affordable delivery service can get you the medication you need quickly and conveniently.',
			link: 'medication-delivery',
			link_text: 'Find out more',
		},
		/*{
			img:'images/megamenu/woman-smiling-holding-parcel.jpg',
			alt: 'woman smiling receiving parcel',
			title: 'Medication subscriptions',
			desc: 'See if you are eligible for our repeat subscription service.',
			link: '#',
			link_text: 'Find out more',
		},*/
	],
	telehealth: [
		{
			img: 'https://storage.googleapis.com/instant-med-public/megamenu/doctor-sarah.jpg',
			alt: 'InstantScripts doctor',
			title: 'Telehealth consultations',
			desc: 'Learn more about our doctors and telehealth services.',
			link: '/telehealth-consultations/',
			link_text: 'Find out more',
		},
		{
			img: 'https://storage.googleapis.com/instant-med-public/megamenu/young-guy-talking-on-phone.jpg',
			alt: 'woman on phone smiling',
			title: 'Services we offer',
			desc: 'View our range of telehealth consultation services.',
			link: '/cat/DocCons',
			link_text: 'View our telehealth consultations',
		},
		{
			img: 'https://storage.googleapis.com/instant-med-public/megamenu/iStock-1186116325.jpg',
			alt: 'young man talking on phone smiling',
			title: 'Child and family consultations',
			desc: 'We can help your family members access our services.',
			link: '/childrens-doctor-online/',
			link_text: 'Find out more',
		},
	],
	certs: [
		{
			img: 'https://storage.googleapis.com/instant-med-public/megamenu/guy-looking-at-phone-smiling.jpg',
			alt: 'guy on phone smiling',
			title: 'About medical certificates',
			desc: 'Find out how you can request a medical certificate online.',
			link_text: 'Find out more',
			link: '/online-medical-certificates/',
		},
		{
			img: 'https://storage.googleapis.com/instant-med-public/megamenu/med-cert-icon-blue-bg.jpg',
			alt: 'medical certificate icon',
			title: 'Medical certificates we offer',
			desc: 'View our range of medical certificates for work, uni and carer\'s leave.',
			link_text: 'Request a medical certificate',
			link: '/c/pharm/certs/',
		},
		{
			img:'https://storage.googleapis.com/instant-med-public/megamenu/is-medical-certificate.jpg',
			alt: 'medical certificate',
			title: 'Information for employers',
			desc: 'Find out about our unique certification process for medical certificates.',
			link_text: 'Find out more',
			link: '/online-medical-certificates-employers/',
		},
	],
}

const list = {
	treat: [{
		title: 'Common Conditions',
		list: [
			{name: 'Anxiety', link:'-LMrOQSVQMBVgUqe3y2A'}, 
			{name: 'Anaphylaxis', link:'-M34DQ_Bi2Qs7693U5-H'}, 
			{name: 'Asthma', link:'-LJVyR5Pdls_ydzW8sRk'},
			{name: 'Blood pressure', link:'-LJlvQo2RunU9oi9abk5'}, 
			{name: 'Cholesterol', link:'-LJcGNXMyADt_gxi2m-f'}, 
			{name: 'Contraception', link:'womens/contraception'}, 
			{name: 'COVID-19',link:'-N9YuK8ZZsDznRtdISNF'}, //local '-N9YsHRr3TGZ5G1tb_vx'
			{name: 'Diabetes', link:'-LTmNvHxxWf4NuDd8rMn'}, 
			{name: 'Depression', link:'-LMrOQSVQMBVgUqe3y2A'}, 
			{name: 'Erectile dysfunction', link:'mens/erectile-dysfunction'}, 
			{name: 'Gout', link:'-LTLTLfVHJG0fyp2GO44'}, 
			{name: 'Hair loss', link:'mens/hair'}, 
			{name: 'Hayfever', link:'-LOJ8nO6CQ9dJLu7lTIo'}, 
			{name: 'Heartburn & Reflux', link:'-LJcNCNA4ONtItNNzXB0'}, 
			{name: 'Migraine and headaches', link:'-LMfGlpF3oP3xgL52d7w'}, 
			{name: 'Morning sickness', link:'womens/nausea'}, 
			{name: 'Menopause', link:'womens/hrt'}, 
			{name: 'Pain and inflammation', link:'-LJn8ZK_ufxt8fWpDyl8'},  
			{name: 'Premature ejaculation', link:'mens/premature-ejaculation'},
			// {name: 'Sinusitis', link:'-LOJ8nO6CQ9dJLu7lTIo'},  
			{name: 'Sleep', link:'-LQ-1WaFZZIdgfFLBsZC'},  
			{name: 'STIs', link:'-LwGrL-z9Tx_cVxQH088'},  
			{name: 'Skin conditions', link:'-LJVyeBvRW_BnUCsx0kw'},  
			{name: 'Smoking cessation', link:'-M2kr-X-SGFjWTib-pYM'},  
			{name: 'Thyroid disease', link:'-LUTBLS-EhdO6PD5Z_Tb'}, 
			{name: 'Urinary incontinence', link:'-LXa3IJ_uqU-Tc348e1S'},  
			{name: 'UTIs', link:'womens/uti'},  
			{name: 'Weight loss', link:'/weight-loss', lp:true},
		],
		link_text: 'View all health conditions',
		link: '/cat'}
	]
}

// 		--------------------------------		--------------------------------		---------

const MegaMenu = ({inSideMenu, cls='main', setMainSideMenu }) => {
	const [current, setCurrent] = useState();
	const [referenceElement, setReferenceElement] = useState()
	const [sideMegaMenu, setSideMegaMenu] = useState(true)
	const [popperElement, setPopperElement] = useState()
	const {styles, attributes} = usePopper(referenceElement, popperElement)
	const [mainMenu, setMenu] = useState([])
	const [panel, setPanel] = useState()

	useEffect(() => {
		const menu = menu_model.get_megamenu(inSideMenu, '/');
		setMenu(menu)
	}, [inSideMenu])
	
	const handleShowMenu = (val) => {
		setSideMegaMenu(val)
		setMainSideMenu?.(val)
	}

	const handleOpen = (open) => {
		useEffect(() => {
			!open && handleShowMenu(true)
		}, [open])
	}

	// 		--------------------------------		--------------------------------		---------

	useEffect(() => {
		setPanel(
		    <Popover.Panel 
				ref={popperElement}
				className={clsn(inSideMenu ? "side-menu h-full overflow-y-auto pb-24 mt-[72px]" 
				: "lg:px-9 mt-6 bg-is-gray-20", "panel bg-white w-full shadow-lg z-50",)}
				style={styles.popper}
				{...attributes.popper}
				>
				<div className={clsn(!inSideMenu && "md:grid-cols-3 p-4 sm:px-8 md:py-10 lg:p-10 gap-4", "relative grid w-full grid-cols-1 mx-auto max-w-screen-menu-xl")}>
					{/* side section */}
					<div className={clsn(inSideMenu && "px-4 py-6", "section-side col-span-1 h-full")}>
						 {intro[current] && 
							<>
								<h3 className="text-is-blue font-bold">{intro[current].title}</h3>
								<p className={clsn(!inSideMenu && "xl:w-[90%]")}>{intro[current].desc}</p>
							</>
						}

						{/*{intro_links[current] && 
							<div className="flex flex-col lg:w-3/4">
								{intro_links[current].map((el, i) => {
									return  <div key={i}>
												<p className="text-black font-bold">{el.title}</p>
												<Popover.Button 
													as={Link} to={el.link}
													className="primary ui button p-4 w-auto">
													{el.link_text}
												</Popover.Button>
											</div>
									})}
							</div>
						} */}
					</div>

					{/* main section */}
					<div className="section-main col-span-2 h-full">
						{tiles[current] &&
							<div className={clsn(!inSideMenu ? "sm:grid-cols-2 lg:grid-cols-3 pb-[80px] md:pb-0 gap-1" : "px-2", "tiles grid grid-cols-1")}>
							{tiles[current].map((el, i) => {
								const content = <><span className="flex justify-between items-stretch mb-2">
													<span>
													<p className="font-bold mt-0">{el.title}</p></span>
													<span className="flex items-start">
													{el.new && <span className="bg-is-blue text-white text-sm p-2 font-bold rounded-[1.25rem] max-h-5 mx-1 mt-1 flex items-center">New</span>}
													{el.ext ? <SIcon size="xs" name="external" cls="text-is-black hover:text-is-blue" bold/> :
													<SIcon size="xs" name="chevron" svg_cls="-rotate-90" cls="text-is-black hover:text-is-blue" bold/>}</span>
												</span>
												<p className="w-[90%] desc text-sm">{el.desc}</p>
												</>
								
								return  <Popover.Button 
											as={el.link?.startsWith('https://') ? 'a' : Link} href={el.link}
											key={i} to={el.link}
											className={clsn(inSideMenu && "border-t border-is-50 rounded-none", "hover:border-transparent tile col-span-1 text-is-black h-full p-4 cursor-pointer rounded-lg hover:bg-is-blue-20 min-h-[104px]")} 
											onClick={() => {inSideMenu && handleShowMenu(true)}}
											>
										{content}
										</Popover.Button>
								})}
							</div>
						}

						{image_links[current] && 
							<div className={clsn(inSideMenu ? "grid grid-cols-1 border-t border-is-50 pb-[80px]" : "grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-6")}>
								{image_links[current].map((el, i) => {
									return  <div key={i} className={clsn(inSideMenu && "border-b border-is-50", "image-link col-span-1 text-is-black hover:text-is-blue h-full w-full")}>
												<Popover.Button
													as={el.link?.startsWith('https://') ? 'a' : Link} to={el.link} href={el.link}
													onClick={() => {inSideMenu && handleShowMenu(true)}}
													className="block text-is-black">
													<div style={{backgroundImage: `url(${el.img})`}} className={`${inSideMenu ? "hidden" : "pt-[66%]"} bg-cover rounded-md mb-2`}></div>
													<p className="mt-0 font-bold">{el.title}</p>
													<p className="md:min-h-[52px] text-sm mb-0">{el.desc}</p>
													<span className="info-link text-is-blue mr-2 hover:text-is-black text-sm" href={el.link}>{el.link_text}</span>
												</Popover.Button>
											</div>
								})}
							</div>
						}

						{list[current] && list[current].map((el, i) => {
							return  <div key={i} className={clsn(inSideMenu && "pb-[80px]", "list text-is-black hover:text-is-black rounded-md w-full")}>
									<h5 className={clsn('px-4', !inSideMenu && 'sm:px-0')}>{el.title}</h5>
									<div className={clsn(!inSideMenu ? "md:h-[650px] lg:h-[410px]": "h-full", "list flex flex-wrap flex-col")}>
									{el.list.map((el, i) => 
											<Popover.Button 
												key={i} as={Link} to={el.lp ? el.link : `/c/pharm/${el.link}`}
												onClick={() => {inSideMenu && handleShowMenu(true)}} 
												className="mr-4 text-sm">
												{el.name}
											</Popover.Button>)
										}
										<span className='flex'>
											<Popover.Button className={clsn("info-link text-is-blue hover:text-is-black text-sm", !inSideMenu && "mr-2")} as={Link} to={el.link}><span>{el.link_text}</span></Popover.Button>
										</span>
									</div>
								</div>
							})
						}
					</div>
				</div>
			</Popover.Panel>)
	}, [current])

   // 		--------------------------------		--------------------------------		---------

   return (
	<Popover.Group className={`flex justify-end w-full mx-auto mega-menu ${inSideMenu ? "flex-col" : "flex-row items-center mr-2"}`}>

	{mainMenu.length > 0  && mainMenu?.filter(Boolean).map((el, i) => {
	  return <Popover key={i}>
		{({ open, close }) => (

		  <>
		    {/* Main Menu */}
			{inSideMenu && handleOpen(open)}

			{inSideMenu && !sideMegaMenu && 
				<span className="text-is-black hover:text-is-blue"><button onClick={() => {
					handleShowMenu(true)
					close(referenceElement)}} 
					className="btn back flex items-center bg-none w-1/2 absolute top-[8px] left-[8px] py-4 cursor-pointer"
					>
					<SIcon name="chevron" size="xxs" svg_cls='rotate-90 mr-1' bold/>
					Back
				</button></span>
			}

		    <div className="relative z-10">
					  <div className={clsn(inSideMenu ? "px-0" : "xl:px-1.5 2xl:p-2")}>
					<Popover.Button
						ref={setReferenceElement}
						key={i}
						onClick={() => { 
							setCurrent(el.key)
							inSideMenu && handleShowMenu(false)
						}}
						className={clsn(cls, el.cls,
						inSideMenu && !sideMegaMenu && "hidden",
						!inSideMenu && "items-center",
						inSideMenu ? "side-btn w-full p-4 hover:bg-is-blue-20" : "hover:text-is-blue ml-4 rounded-md text-sm",
						open ? "open text-is-blue" : "text-is-black", 
						"popover-btn font-semibold button group flex inline-flex items-end")}>
						<span className="max-w-[80%] text-left sm:max-w-none sm:text-center">{el.name}</span>
						{inSideMenu && <SIcon name="chevron" size="xs" svg_cls='-rotate-90 mr-1' cls="absolute right-4 text-is-black group-hover:text-is-blue" bold/>}
					</Popover.Button>
				</div>
		    </div>

		    {/* Dropdown Menu */}
			{ panel }

		  </>
	 	)}
	  </Popover>     
	})}
	</Popover.Group>

  )
}

export default MegaMenu
