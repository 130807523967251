import {onDisconnect, serverTimestamp, update} from "firebase/database";
import {useEffect} from "react";
import { useSiteStatus } from "xAppLib/Hooks"
import instcons_model from "../../models/instcons_model";
import {useCurrent} from "../../xAppLib/Hooks/useCurrent";
import firebase_database from "../../xAppLib/providers/firebase_database";

function can_approve(approve_wr = {}) {
	if (!(app.acl.is_admin || app.acl.is_doc_gp || app.acl.is_supp))
		return false
	if (Array.isArray(approve_wr.always) && (approve_wr.always.includes(app.user.claims?.doc_id) || approve_wr.always.includes(app.user.uid)))
		return true
	if (!approve_wr.active)
		return false
	return Array.isArray(approve_wr.access) ? approve_wr.access.includes(app.user.claims?.doc_id) || approve_wr.access.includes(app.user.uid) : true
}

export function useCanApprove() {
	const approve_wr = useSiteStatus('approve_wr') || {}    
    return can_approve(approve_wr)
}

export function useNurseDeviceOnline(id, record) {
	const isFinal = useCurrent(instcons_model.is_final(record));

	useEffect(() => {
		instcons_model.update_record(`${id}/req`, { vr: `${__GIT_HASH__} ${__BUILD__}` });

		const ref = firebase_database.ref(`${instcons_model.FRDB_LOC}/${id}/dvc`);
		function register_online(e) {
			if (app.user.online && (!e || e === 'UserOnlineChanged')) {
				update(ref, { online: serverTimestamp(), offline: null })
			}
		}
		app.on(app.events.USER_UPD, register_online);
		register_online();

		const offline = {
			online: false,
			offline: serverTimestamp()
		};
		onDisconnect(ref).update(offline);

		return () => {
			// don't try and update if the consult is in a terminal state,
			// we no longer need to track it and rules prevent changes anyway
			if (!isFinal.current) {
				update(ref, offline);
			}
			app.off(app.events.USER_UPD, register_online);
			onDisconnect(ref).cancel();
		};
	}, [id]);
}