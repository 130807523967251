import React from 'react';
import {Icon} from "semantic-ui-react";

const STATUS_MAP = status => {
	switch (status) {
		case 'instcons_approved':
			return {
				label: 'Approved',
				colour: 'green',
				icon: 'check'
			};
		case 'instcons_rejected':
			return {
				label: 'Rejected',
				colour: 'red',
				icon: 'x'
			};
		case 'await_approval':
			return {
				label: 'Pending Review',
				colour: 'grey',
				icon: 'clock outline'
			};
		case 'cancelled':
		case 'instcons_cancel':
			return {
				label: 'Cancelled',
				colour: 'red',
				icon: 'dont'
			};
		default:
			return {
				label: status,
				colour: 'pink',
				icon: 'question'
			};
	}
}

export function TreatmentStatusIcon({status}) {
	const {icon, colour} = STATUS_MAP(status);

	return <Icon color={colour} name={icon}/>;
}

export function TreatmentStatus({status}) {
	const {label} = STATUS_MAP(status);

	return (
		<span className="whitespace-nowrap">
			<TreatmentStatusIcon status={status}/> {label}
		</span>
	);
}