import React, { Component} from 'react'

import { Link } from 'react-router-dom'
import {
		Icon,
		Button,
		Popup,
		List,
	} from 'semantic-ui-react'

import moment from 'moment'
import parser from 'ua-parser-js'
import currency from 'currency.js'

import { ObjectInspector, chromeLight } from 'react-inspector';

import getNestedObject from '../helpers/getNestedObject'

import SimpleShowMore from '../UIelems/SimpleShowMore'
import ClaimsEditor from '../Users/ClaimsEditor'

import PhoneCalls from 'xAppLib/PhoneCall/PhoneCalls'
import { color_levels, Duration, format_hm } from "../UIelems/Duration";
import CopyField from '../UIelems/CopyField'
import Age from '../../views/patients/Age'
import { numberOfMissedCalls } from '../../views/doc/util'


const use_firebase = true;

const defaultCompoundTemplate = (data) => <React.Fragment>
		{Object.keys(data).map(p=><React.Fragment key={p}>{p}: {data[p]}<br/></React.Fragment>)}
</React.Fragment> 

function compareDiffFields(diff, prefix = null) {
	function formatFieldValue(obj, key) {
		if (typeof obj != 'object') 
			return `[${key}]`;
		if (key in obj) {
			const date = /^\d{4}-\d{2}-\d{2}/.test(obj[key]) && moment(new Date(obj[key]));
			if (date?.isValid?.()) {
				return date.tz("Australia/Melbourne").format('D/M/YY HH:mm')
			}
			return obj[key];
		}
		return '(not set)';
	}

	// Just show the "to" fields. From sometimes contains a lot of extra stuff that wasn't actually changed
	return diff?.to && Object.keys(diff?.to)
		// Some records have null in both from and to, so skip those
		.filter(field => !(diff.to[field] === null && diff.fro[field] === null))
		.flatMap(field => {
			const from = formatFieldValue(diff.fro, field);
			const to = formatFieldValue(diff.to, field);

			const path = [prefix, field].filter(Boolean).join('.');
			// value is a nested object (e.g. dets, med, etc)
			if (!Array.isArray(from) && typeof from === 'object' && from !== null) {
				// and we're not already within a level of recursion
				if (prefix === null) {
					// diff the nested object
					return compareDiffFields({fro: from, to}, path);
				}
			}

			// status("active" => "annul")
			return [`${path}(${JSON.stringify(from)}=>${JSON.stringify(to)})`]
		})
		.join(', ')
}

export default class DataShow {

	static show_hist_data = (v, index) => v?.map( r => {
										if (typeof r == 'string')		r =  JSON.parse(r);
										if (typeof r.diff == 'string')		r.diff =  JSON.parse(r.diff);
										const time = moment(r.tm).tz("Australia/Melbourne").format('D/M/YY HH:mm:ss')
										return <li key={index}>
											{time}
											&nbsp; - {r.by?.n||r.by?.e||r.by?.eml||'Auto'}
											{(r.c?.t || r.c?.a) && <>&nbsp; - <b>{r.c?.t}/{r.c?.a}</b></> ||
                                            r.diff?.part=='usr_prof' && <>&nbsp; - <b>User Profile</b></> ||''}

											{
												r?.act=='update-item-answer' && <>
                                                    &nbsp; - set answer  {JSON.stringify(r.diff?.to?.answer)}
                                                    {r.diff?.fro?.answer && <>&nbsp; - (from {JSON.stringify(r.diff?.fro?.answer)}) </>}
                                                </>

												|| r?.act=='add-item-evidence' && <>
                                                    {r.diff.to.evidence && <>&nbsp; - add evidence{r.diff.to.evidence.filter(e1 => !r.diff?.fro?.evidence?.find(e2 => e1.img_fn === e2.img_fn)).map(e => <><br /> - {e.comn || e.img_fn}</>)} </>}
                                                </>

												|| r?.act=='toggle-item-not-applicable' && <>
                                                    &nbsp; - toggle not-applicable - {r.diff.to.not_applicable ? "TRUE" : "FALSE"}
                                                </>

												|| r?.act=='remove-item-evidence' && <>
                                                    {r.diff.fro.evidence && <>&nbsp; - remove evidence{r.diff.fro.evidence.filter(e1 => !r.diff?.to?.evidence?.find(e2 => e1.img_fn === e2.img_fn)).map(e => <><br /> - {e.comn || e.img_fn}</>)} </>}
                                                </>

												|| r.diff?.part=='status' && <>
																	&nbsp; - <b>{r.diff?.part}</b> 
																	&nbsp; - set {r.diff?.to?.is_script==true&&'IS_script'||r.diff?.to?.is_script==false&&'NO_script'} {r.diff?.to?.is_script!=null && r.diff?.fro?.is_script==true&&'(from IS_script)'||r.diff?.fro?.is_script==false&&'(from NO_script)'} 
																	&nbsp; - set status {JSON.stringify(r.diff?.to?.status)} 
																	&nbsp; - (from {JSON.stringify(r.diff?.fro?.status)}) 
																</>

												|| r.diff?.part=='add_prtn' && <>
																	&nbsp; - Add Prtn
																	&nbsp; - <b>{r.diff?.to?.prtn}</b> 
																	&nbsp; - from {r.diff?.to?.req?.referer}
																</>
												|| r.diff?.part=='meta' && r?.by?.e === 'ev.operator@instantscripts.com.au' && 
												<b>Evermed order update received</b> 
												

												|| r.diff?.part=='meta' && r.diff.to?.note && <>
																	&nbsp; - <b>Note</b> 
																	&nbsp; - {r.diff?.to?.note} 
																</>
												|| ['msg', 'note'].includes(r.diff?.part) && r.diff.to?.[r.diff.part] && <>
																	&nbsp; - <b>{r.diff.part}</b> 
																	{/*&nbsp; - {r.diff?.to?.[r.diff.part].toString()} */}
																	&nbsp; - {JSON.stringify(r.diff?.to?.[r.diff.part].replace(/\(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b\)/g, ''))}
																</>
												|| r.diff?.part=='meta' && r.diff.to?.medicare_verified && <>
																	&nbsp; - <b>Medicare Number Verified</b> 
																	&nbsp; - {r.diff?.to?.medicare_verified} 
																</>
												|| r.diff?.part=='meta' && r.diff.to?.sent_doccons_wait_eml && <>
																	&nbsp; - <b>Doccons waiting email sent</b> 
																</>
												|| r.diff?.part=='paym-mdcr' && <>
																	&nbsp; - <b>Set Medicare Items</b> 
																	&nbsp; - ${r.diff?.to?.itm?.amount} {r.diff?.to?.itm?.desc} 
																	&nbsp; {r.diff?.to?.extras?.map( e=> ' + $' + e.amount + ' ' + e.desc  ) } 
																</>

												|| (r.diff?.part=='usr_prof' || r.diff?.to?.first_name || r.diff?.to?.last_name || r.diff?.to?.dob || r.diff?.to?.address || r.diff?.to?.medicare || r.diff?.to?.ccs) && r.diff?.to?.ccs && r.diff?.to.ccs[0]?.CC_tok
																&& <>
																	&nbsp; - <b>User - Add CC</b> 
																	&nbsp; - {r.diff?.to.ccs[0]?.CC_tok.cardType} {r.diff?.to.ccs[0]?.CC_tok.PAY_ENV} {r.diff?.to.ccs[0]?.CC_tok.maskedPan}
																</>

												|| (r.diff?.part=='usr_prof' || r.diff?.to?.first_name || r.diff?.to?.last_name || r.diff?.to?.dob || r.diff?.to?.sex || r.diff?.to?.address || r.diff?.to?.medicare) 
																&& <>
																	{/*&nbsp; - {r.diff?.tbl!='orgs' && <b>User Profile</b> ||''} */}
																	{r.diff?.fro && ` - from ${JSON.stringify(r.diff?.fro)}` ||''}
																	{r.diff?.to && ` - to ${JSON.stringify(r.diff?.to)}` ||''}
																	{!r.diff?.fro && !r.diff?.to && ` - to ${JSON.stringify(r.diff)}` ||''}
																</>

												|| r.diff?.part=='act'
																&& <>
																	<b>{r.diff?.act}</b> - {r.diff?.det}
																</>
												|| r.diff?.part=='shipping_address'
																&& <>
																	<b>Updated Shipping Address</b> - To: {r.diff.to?.shipping_address} - From: {r.diff.fro?.shipping_address || ''}
																</>
												|| r.diff?.part=='shipping_method'
																&& <>
																	<b>Updated Shipping Method</b> - To: {r.diff.to?.delivery} - From: {r.diff.fro?.delivery || ''}
																</>

												|| (r.diff?.part === 'paym' && r.diff?.to?.paym?.refund)
																&& <>
																	&nbsp; - <b>Refunded</b> 
																</>

												|| r.diff?.part=='phts'
																&& <>
																	&nbsp; - <b>Photos</b> 
																	&nbsp; - Added {Object.keys(r.diff.to).length} photos
																</>
												|| r.diff?.part=='attch_seen'
																&& <>
																	&nbsp; - Patho Result {r.diff.to?.doc_seen ? 'Seen' : 'Unseen'}
																	&nbsp; - {r.diff.to?.file_fn || r.diff.to?.comn} - <b>From:</b> {r.diff.fro?.doc_seen ? 'Seen' : 'Unseen'}
																</>
												|| r.diff?.part=='all_attch_seen'
																&& <>
																	&nbsp; - All Patho Result {r.diff.to?.doc_seen ? 'Seen' : 'Unseen'} ({r.diff.to?.attch_len})
																</>
												|| r.diff?.part=='upd_patho_flask'
																&& <>
																	&nbsp; - Updated Patho Flask
																</>
												|| r.diff?.part=='new_patho_attch'
																&& <>
																	&nbsp; - New Patho File Added <b>({r.diff.to?.files?.length})</b>
																	&nbsp; - {r.diff.to?.files?.map(file => <span key={file.file_fn}>{file.file_fn} - (via <b>{file.origin}</b>)</span>)}
																</>
												|| r.diff?.part=='patho_release_rdy'
																&& <>
																	&nbsp; - Patho Release Ready: <b>{r.diff.to.patho_release_rdy.res === 'err' ? 'No' : 'Yes'}</b>
																	&nbsp; - {r.diff.to.patho_release_rdy.res === 'err' ? `${r.diff.to.patho_release_rdy.err}` : `${r.diff.to.patho_release_rdy.detail}`}
																</>

												|| r.diff?.part=='patho_res_ack'
																&& <>
																	&nbsp; - All pathology results seen: <b>{r.diff.to?.patho_res_ack?.all_seen === true ? 'Yes' : 'No'}</b>
																</>

												|| r.diff?.part === 'send_factsheets'
																&& <>
																	&nbsp; - <b>Factsheets</b>
																	&nbsp; - Sent {Object.keys(r.diff.slugs).length} factsheets
																	&nbsp; - {r.diff.slugs.join(', ')}
																</>

												|| r.diff?.fro && r.diff?.to && <>- {compareDiffFields(r.diff)}</>

												||
													<>&nbsp; - ({JSON.stringify(r.diff)})</>
											}
											
											<br/>
										</li> 
									} )

	// ---- --------------------------------------------  --------------------------------------------

	static show_sesh_evnts = (v, show_all=false) => v?.map( r => {
										if (typeof r == 'string')		r =  JSON.parse(r);
										// if (typeof r.diff == 'string')		r.diff =  JSON.parse(r.diff);
										return <li key={r}>
											{r.tm} 
											{r.logName=='e-api' && ` - AUTO API`||''} 
											&nbsp; - a: {r.a||r.act||''} 
											&nbsp; - pg: {r.p||r.ld} 

											{show_all &&
											<>
											<br/><br/>

											{
												r.diff?.part=='status' && <>
																	&nbsp; - <b>{r.diff?.part}</b> 
																	&nbsp; - set {r.diff?.to?.is_script==true&&'IS_script'||r.diff?.to?.is_script==false&&'NO_script'} {r.diff?.to?.is_script!=null && r.diff?.fro?.is_script==true&&'(from IS_script)'||r.diff?.fro?.is_script==false&&'(from NO_script)'} 
																	&nbsp; - set status {JSON.stringify(r.diff?.to?.status)} 
																	&nbsp; - (from {JSON.stringify(r.diff?.fro?.status)}) 
																</>
												|| r.diff?.part=='meta' && r.diff.to?.note && <>
																	&nbsp; - <b>Note</b> 
																	&nbsp; - {r.diff?.to?.note} 
																</>
												|| r.diff?.part=='msg' && r.diff.to?.msg && <>
																	&nbsp; - <b>Message</b> 
																	{/*&nbsp; - {r.diff?.to?.msg.toString()} */}
																	&nbsp; - {JSON.stringify(r.diff?.to?.msg)}
																</>
												|| r.diff?.part=='meta' && r.diff.to?.medicare_verified && <>
																	&nbsp; - <b>Medicare Number Verified</b> 
																	&nbsp; - {r.diff?.to?.medicare_verified} 
																</>
												|| r.diff?.part=='paym-mdcr' && <>
																	&nbsp; - <b>Set Medicare Items</b> 
																	&nbsp; - ${r.diff?.to?.itm?.amount} {r.diff?.to?.itm?.desc} 
																	&nbsp; {r.diff?.to?.extras?.map( e=> ' + $' + e.amount + ' ' + e.desc  ) } 
																</>

												|| (r.diff?.part=='usr_prof' || r.diff?.to?.first_name || r.diff?.to?.last_name || r.diff?.to?.dob || r.diff?.to?.address || r.diff?.to?.medicare || r.diff?.to?.ccs) && r.diff?.to?.ccs && r.diff?.to.ccs[0]?.CC_tok
																&& <>
																	&nbsp; - <b>User - Add CC</b> 
																	&nbsp; - {r.diff?.to.ccs[0]?.CC_tok.cardType} {r.diff?.to.ccs[0]?.CC_tok.PAY_ENV} {r.diff?.to.ccs[0]?.CC_tok.maskedPan}
																</>

												|| (r.diff?.part=='usr_prof' || r.diff?.to?.first_name || r.diff?.to?.last_name || r.diff?.to?.dob || r.diff?.to?.address || r.diff?.to?.medicare) 
																&& <>
																	&nbsp; - <b>User Profile</b> 
																	{r.diff?.fro && ` - from ${JSON.stringify(r.diff?.fro)}` ||''}
																	{r.diff?.to && ` - to ${JSON.stringify(r.diff?.to)}` ||''}
																	{!r.diff?.fro && !r.diff?.to && ` - to ${JSON.stringify(r.diff)}` ||''}
																</>

												||
													<>&nbsp; - {r.ld||''} &nbsp; - (<pre>{JSON.stringify(r, null, 4)}</pre>)</>
											}
											
											<br/>
											</>
											}
										</li> 
									} )

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static show_data_field (r, f, s, cl, cust_arr, data_only, props) {
			if (!r || !f)
				return '';


			let val = (f.jpath||f.path) && getNestedObject( r, f.jpath||f.path, '.' )

			if ((val == undefined || val=='') && f.jpath1)			val = getNestedObject( r, f.jpath1, '.' )
			if ((val == undefined || val=='') && f.jpath2)			val = getNestedObject( r, f.jpath2, '.' )
			if ((val == undefined || val=='') && f.jpath3)			val = getNestedObject( r, f.jpath3, '.' )
			if ((val == undefined || val=='') && f.jpath4)			val = getNestedObject( r, f.jpath4, '.' )
			if ((val == undefined || val=='') && f.jpath5)			val = getNestedObject( r, f.jpath5, '.' )

			if (val === undefined)
				val = r

			const altval = f.altpath && getNestedObject( r, f.altpath, '.' )

			if (f.show_if && !DataShow.show_data_field(r, f.show_if, s, cl, cust_arr, data_only, props))
				return ''


			// console.log('DataShow :: show_data_field ', r, f, s, ' -- ', val);


			if (!f.type) {
				const final = (val !== false && val !== undefined) ? val : ''
				return f.template ? <f.template value={final} row={r} f={f} {...props} /> : final
				
			}


			switch (f.type) {
				
				case 'compound':
					const data = f.parts?.reduce((o,p)=>{
						o[p.name] = DataShow.show_data_field(r,p, s, cl, cust_arr, data_only,props)
						return o
					},{})
					if (data_only) {

						let data_obj = {}

						Object.keys(data).map( p=> {
								if (typeof f_data === 'object') 
									data_obj = {...data_obj, ...data[p]} 
								else
									data_obj[p] = data[p]
									// data_obj[f.name + " - " + p] = data[p]
							} )
						return data_obj
					}
					return f.template ? <f.template {...data} row={r} f={f} {...props} /> : defaultCompoundTemplate(data,r);

				case 'compound_popup':
					const comp_data = f.parts.reduce((o,p)=>{
						o[p.name] = DataShow.show_data_field(r,p, s, cl, cust_arr, data_only)
						return o
					},{})
					if (data_only) {
						// console.log('comp_data', comp_data);
						let data_obj = {}
						Object.keys(comp_data).map( p=> data_obj[p] = comp_data[p])
						// Object.keys(comp_data).map( p=> data_obj[f.name + " - " + p] = comp_data[p])
						return data_obj
					}
					const comp_val = f.template ? f.template(comp_data) : defaultCompoundTemplate(comp_data)
					return comp_val && comp_val.toString().length && <Popup 
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} />}
									content={Array.isArray(comp_val) ? <List items={comp_val} /> : comp_val} 
									wide='very'
									on={['click']}
								 />

				case 'sublast':
					return val && val.length && ('...'+val.substr(-7)) || ''


				case 'substr':
					return val?.substr(f.sub_fr, f.sub_to) || ''


				case 'stlookup':
					// console.log(s, f.stdata, s[f.stdata], val);
					return val && f.stdata && s[f.stdata] && s[f.stdata][val] && s[f.stdata][val][f.showfld] || ''


				case 'dict':
					// return val  || '-'
					// console.log(s, f.stdata, s[f.stdata], val);
					return  val && 
								( f.func && typeof f.func == 'function' && f.func(val) || f.vals && f.vals[val] || val )
							|| ''


				case 'json':
					val && val.length && console.log(1, r, f, val);
					return val && JSON.stringify( val ) || ''


				case 'time':
					return val
							&& moment(val).format(f.frmt || "D/M/YY H:mm")
		 					|| ''

				case 'date':
					return val 
							// && moment(val).format("ddd, MMMM Do YYYY")
							&& moment(val).format("D/M/YY")
		 					|| ''


				case 'age':
					return val 
							&& <Age dob={val} seed={r?.tm} />
		 					|| ''
				case 'wd_date':
					return val
							&& moment(val, f.dt_frmt).format(f.frmt || "D/M/YY  (dddd)")
		 					|| ''
				case 'week_date':
					return val
							&& moment(val, f.dt_frmt).format(f.frmt || "\\w \\s D/M/YY")
		 					|| ''

				case 'month_date':
					return val
							&& moment(val, f.dt_frmt).format(f.frmt || "MMM YY")
		 					|| ''

				case 'year_date':
					return val
							&& moment(val, f.dt_frmt).format(f.frmt || "YYYY")
		 					|| ''


				case 'last':
					const l1 = val
					const l1l = l1 && Object.values( l1 )[Object.keys(l1).length-1]
					return l1 && l1l && getNestedObject( l1l, f.jpath1, '.' ) || ''


				case 'useragent': {
					let ua = parser( val );
					return val 
							&& (data_only 
									? `${ua.browser.name} ${ua.browser.major}, ${ua.os.name||''} ${ua.os.version||''}, ${ua.device.model||''} ${ua.device.type||''}` 
									: <Popup content={`${ua.browser.name} ${ua.browser.major}, ${ua.os.name||''} ${ua.os.version||''} ${ua.cpu.architecture||''}, ${ua.device.model||''} ${ua.device.type||''}`} 
									trigger={<div>{ua.browser.name}, {ua.os.name||''}, {ua.device.model||''} {ua.device.type||''}</div>} />)
							|| ''
				}

				case 'last_useragent': {
					const lul1 = val
					const lul1l = lul1 && Object.values( lul1 )[Object.keys(lul1).length-1]
					let ua = parser( lul1 && lul1l && getNestedObject( lul1l, f.jpath1, '.' ) );
					return lul1l && getNestedObject( lul1l, f.jpath1, '.' ) && `${ua.browser.name} ${ua.browser.version}, ${ua.os.name||''} ${ua.os.version||''}, ${ua.device.model||''} ${ua.device.type||''}` || ''
				}

				case 'count':
					return val && Object.values(val).length || ''

				case 'count_values':
					return val && (Array.isArray(val) && val || Object.values(val)).reduce((v,a)=>v+a,0)

				case 'link':
					return data_only ? val : 
						(f.button ? 
						<Button 
								as = {Link} 
								to = {val}
								content = {f.txt}
								icon = {f.icon}
								size = {f.size}
								compact = "true"
							/>
						:
						<Link to = {val}>
							{f.icon && <Icon name = {f.icon} />}
							{f.txt || ''}
						</Link>)

				case 'bool_data':
					return !!val

				case 'bool':
					return data_only ? val :
							<Icon
								disabled={!val}
								name={val?'check':'minus'}
								color={val?'green':'red'}
							 />

				case 'bool_neg':
					return data_only ? val :
							!val && <Icon
								disabled={!val}
								name={val?'check':'minus'}
								color={val?'green':'red'}
							 />

				case 'last_arr_bool':
					const lv = val && val.length ? val[val.length-1] : null
					return lv!=undefined && <Icon
								disabled={!lv}
								name={lv?'check':'minus'}
								color={lv?'green':'red'}
							 />

				case 'notnull_bool_ne':
					return data_only ? (val==null ? '' : val!=f.ne_val) :
							val && <Icon
								// disabled={!val!=f.ne_val}
								name={val!=f.ne_val?'check':'minus'}
								color={val!=f.ne_val?'green':'red'}
							 />

				case 'boolWact':
					return <Button
								basic
								icon={val?'check':'minus'}
								// disabled={!val}
								// name={val?'check':'minus'}
								color={val?'red':'green'}
								// onClick={ _=>cl(r, f) }
								// onClick={ _=> f.funcs.bool_act&&f.funcs.bool_act&&f.funcs.bool_act(r, f) }
								onClick={ _=> f.funcs&&f.funcs.bool_act&&f.funcs.bool_act(r, f) || cl(r, f) }
							 />

				case 'if_val_bool':
					return val==f.if_val

				case 'or':
					return val
							 // || f.jpath1&&getNestedObject( r, f.jpath1, '.' )
							 // || f.jpath2&&getNestedObject( r, f.jpath2, '.' )
							 // || f.jpath3&&getNestedObject( r, f.jpath3, '.' )
							 // || f.jpath4&&getNestedObject( r, f.jpath4, '.' )
							 // || f.jpath5&&getNestedObject( r, f.jpath5, '.' )
							 || ''


				case 'show_more':
					return data_only ? val : (val && <React.Fragment>
						{f.label && <b>{f.label}: </b>}
						<SimpleShowMore cut_length={f.cut_length||10} content={val || ''} />
					</React.Fragment> )


				case 'custom':
					let comp = cust_arr && f.cust_fld && cust_arr[f.cust_fld]
					if (comp && typeof comp == 'function') 
						comp = comp(r,f)
					return comp


				case 'popup':
					return data_only ? JSON.stringify(val) : 
							(val && <Popup 
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} label={f.label} />}
									content={Array.isArray(val) ? <List items={val} /> : val} 
									wide='very'
									on={['click']}
								 />)

				case 'popup_json':
					return data_only ? JSON.stringify(val) : 
							(val && <Popup 
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} label={f.label}/>}
									content={<pre>{JSON.stringify(val, null, 2)}</pre>} 
									wide='very'
									on={['click']}
								 />)

				case 'popup_format':
					return data_only ? JSON.stringify(val) : 
							(val && <Popup 
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} />}
									content={<pre>{val}</pre>} 
									wide='very'
									on={['click']}
								 />)

				case 'popup_func':
					return data_only ? f.func&&f.func(val) : 
							(val && <Popup 
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} label={f.label}/>}
									// trigger={<Button icon={f.ico||'eye'} compact={f.compact} content={val.filter(r => r.diff?.part=='paym-mdcr')[0]?.by?.n} />}
									content={f.func&&f.func(val)}
									wide='very'
									on={['click']}
								 />)

				case 'popup_json_obj':
					return  data_only ? JSON.stringify(val) : 
							(val && <Popup
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} label={f.label} />}
									// position='left center'
									on='click'
									wide='very'
									// style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll'}}
										>
									{/*<pre>{JSON.stringify(row.hist, null, 4)}</pre>*/}
									<ObjectInspector
											data={ val }
											expandLevel={ f.expLev || 2 }
											theme={{...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 })}}
										 />
							 	</Popup> || <Button icon={f.ico||'eye'} compact={f.compact} label={f.label} disabled/>)

				case 'role':
 					return <ClaimsEditor 
										claims={val || {}} 
										onSetClaims={(claims)=>{
											const local_f = {...f,claims}
											f.funcs&&f.funcs.bool_act&&f.funcs.bool_act(r, local_f) || cl(r, local_f)
										}}
										/>
							
					

				case 'currency':
					return `${currency(val, f.rnd&&{ precision: 0 }).format()}`


				case 'multiline':
					if (data_only) {
						let data_obj = {}
						f.lines.map( (l, i) => l.type!='br' &&
									( data_obj[l.jpath||i] = this.show_data_field( r, l, s, cl, cust_arr, data_only ) )
									// ( data_obj[f.name + " - " + (l.jpath||i)] = this.show_data_field( r, l, s, cl, cust_arr, data_only ) )
								)
						return data_obj
					}
					return f.lines.map( (l, i) => <span key={f.name+f.type+i} title={l.title}>{this.show_data_field( r, l, s, cl, cust_arr, data_only )} </span> )

				case 'multiline_nobr':
					if (data_only) {
						let data_obj = {}
						f.lines.map( (l, i) => l.type!='br' &&
									( data_obj[l.jpath||i] = this.show_data_field( r, l, s, cl, cust_arr, data_only ) )
									// ( data_obj[f.name + " - " + (l.jpath||i)] = this.show_data_field( r, l, s, cl, cust_arr, data_only ) )
								)
						return data_obj
					}
					return <nobr>{f.lines.map( (l, i) => <span key={f.name+f.type+i}>{this.show_data_field( r, l, s, cl, cust_arr, data_only )} </span> )}</nobr>


				case 'concat':
					return f.parts.map( (l, i) => this.show_data_field( r, l, s, cl, cust_arr, data_only ) ).join('')


				case 'val':
					return f.val


				case 'fixed':
					return val?.toFixed(f.prec||2)


				case 'b':
					return <b>{val}</b>

				case 'i':
					return <i>{val}</i>

				case 'br':
					return <br />

				case 'label':
					return <b>{f.label || f.name}</b>
					
				case 'copy':
					return data_only ? val : <CopyField val={val} altval={altval} {...f} />


				case 'show_hist':
					return data_only ? this.show_hist_data(val) : 
							<pre>{this.show_hist_data(val)}<Popup
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} />}
									position='left center'
									on='click'
									wide='very'
									// style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll'}}
										>
									{/*<pre>{JSON.stringify(row.hist, null, 4)}</pre>*/}
									<ObjectInspector
											data={ val }
											expandLevel={ f.expLev || 4 }
											theme={{...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 })}}
										 />
							 	</Popup></pre>

				case 'popup_hist':
					return data_only ? this.show_hist_data(val) : 
							(val && <Popup 
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} />}
									// trigger={<Button icon={f.ico||'eye'} compact={f.compact} content={val.filter(r => r.diff?.part=='paym-mdcr')[0]?.by?.n} />}
									content={<pre>{this.show_hist_data(val)}</pre>} 
									wide='very'
									on={['click']}
								 />)

				case 'inline_status_hist':
					return val && this.show_hist_data(val.filter(r => !['note', 'meta', 'paym', 'paym-mdcr'].includes(r.diff?.part)))

				case 'inline_hist_doccons':
					return data_only ? this.show_hist_data(val, 'doccons_hist') : 
							(val && val?.length>1 && <>
									<br/>
									Last event: {moment(val[val.length-1]?.tm).tz("Australia/Melbourne").format('D/M HH:mm')}
									&nbsp; &nbsp;
									<Duration
										start={val[val.length-1]?.tm}
										live
										icon
										compact
										format={format_hm}
										iconColor={color_levels([30*60, 60*60, 90*60, 120*60])}
									/>

									
									{r?.meta?.note && <><br /> Dr Note: <SimpleShowMore cut_length={f.cut_length||25} content={r?.meta?.note} /></>}

									<br />
									Missed calls: <b>{numberOfMissedCalls(val)}</b>
									{/*<br /><br />*/}

									{/*<pre>{this.show_hist_data(val, 'doccons_hist')}</pre>*/}
								</>
							)

				case 'inline_doccons_calls':
					return data_only ? null : 
									<PhoneCalls
										key={r.sid}
										sid={r.sid}
									/>

				case 'popup_sesh_evnts':
					return data_only ? this.show_sesh_evnts(val, f.show_all) : 
							(val && <Popup 
									trigger={<Button icon={f.ico||'eye'} compact={f.compact} />}
									// trigger={<Button icon={f.ico||'eye'} compact={f.compact} content={val.filter(r => r.diff?.part=='paym-mdcr')[0]?.by?.n} />}
									content={<pre>{this.show_sesh_evnts(val, f.show_all)}</pre>} 
									wide='very'
									on={['click']}
								 />)

				case 'icon':
					return data_only ? '' :
							<Icon
								// disabled={!val!=f.ne_val}
								name={f.ico||val}
								color={f.col}
							 />
			}

			return ''
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

}
