import React, { Component } from 'react';
import {
	Card,
	Modal, 
	Image,
	Label, 
	Checkbox, 
	Button, 
	Icon
} from 'semantic-ui-react'
import {CosmeticPhotoDisclaimer} from "../../views/icosm/components/CosmeticPhotoDisclaimer";

import TakePhoto from './TakePhoto'
import { PreviewDocument } from "../../views/UIelems/PreviewDocument";
import { cls as clsn } from 'views/NUI/utils'
import API_service from "../providers/API_service";

function attachedFileUrl({ img_fn, bct }) {
    return (
        API_service.API_BASE_URL +
        `file/attachment/view/${bct}/${encodeURIComponent(
            img_fn
        )}?Authorization=Bearer ${app.user.token}`
    );
}

class Photos extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			new_photos: [...props.data],
			discreet: props.discreet || false,
		};
	}

	// 		--------------------------------		--------------------------------		---------

	add_phot(p) {
		const { onChange } = this.props

		this.setState(
			s => ({ new_photos: s.new_photos.concat(p) }),
			() => onChange?.(this.state.new_photos)
		);

	}

	// 		--------------------------------		--------------------------------		---------
	
	async delete_phot(p) {
		const { onChange } = this.props

		const res = await app.confirm(`Delete ${p.comn || 'Untitled'}`, `Are you sure?`)

		if (res == 'yes') {
			this.setState(
				s => ({ new_photos: s.new_photos.filter(s => s.img_fn != p.img_fn) }),
				() => onChange?.(this.state.new_photos)
			);
		}
	}

    // 		--------------------------------		--------------------------------		---------

    renderFileLink(p) {
        const { uploadType } = this.props;

        if (uploadType || p.tag === "attachment" || p.is_attachment)
            return (
                <PreviewDocument
                    className="text-blue-600"
                    viewOnly
                    doc_name={p.comn || "Untitled"}
                    url={attachedFileUrl(p)}
                >
                    {p.comn || "Untitled"}
                </PreviewDocument>
            );

        return (
            <div className="flex items-center">
                {(!this.props.discreet || this.state.reveal) && (
                    <div
                        className="w-10 h-10 bg-contain bg-no-repeat bg-center mr-2 rounded cursor-pointer"
                        data-testid="file-preview"
                        onClick={(_) => this.setState({ show: p })}
                        style={{
                            backgroundImage: `url(${p.url || `https://storage.googleapis.com/${p.bct}/${p.img_fn}`})`,
                        }}
                    ></div>
                )}
                <p>{p.comn || "Untitled"}</p>
            </div>
        );
    }

    // 		--------------------------------		--------------------------------		---------

    renderCosmDisclaimer() {
		return <CosmeticPhotoDisclaimer/>;
    }

    // 		--------------------------------		--------------------------------		---------

    renderDisclaimer() {
        return (
            <p className="text-sm">Any file you upload will be stored confidentially and securely and will be kept on record in your patient file.</p>
        );
    }

	// 		--------------------------------		--------------------------------		---------

	render() {
		const { inline=false, size = 'calc(100% / 5 - 2em)', mode, show_tag, show_disclaim } = this.props
		const { show, new_photos } = this.state

		return <React.Fragment>
			{show_disclaim &&
                (app?.dvc?.org?.type === "cosm"
                    ? this.renderCosmDisclaimer()
                    : this.renderDisclaimer())}
            {this.props.discreet && new_photos.length > 0 && 
			<Checkbox
				className="mb-4"
				toggle
				label="Reveal pictures"
				onChange={() => this.setState(s => ({ reveal: !s.reveal }))}
				checked={this.state.reveal}
			/>}
			<Card.Group>
				{new_photos.map(p => (
						<Card className={clsn(mode === 'view_only' && 'view', 'file-upload' )} key={p.img_fn}>
							<div key={p.img_fn} className="w-full p-2 flex items-center justify-between">
                                {this.renderFileLink(p)}
								{mode !== 'view_only' && <div className="flex relative gap-2">
									<Button type="button" basic className="icon" data-testid="button-delete-file" onClick={() => this.delete_phot(p)}><Icon name="trash alternate outline" className="text-is-red"/></Button>
								</div>}
							</div>	
							
						</Card>
					))
				}
				
				{mode !== 'view_only' &&
					<TakePhoto
						tag={this.props.tag}
						tag_label={this.props.tag_label}
						target={this.props.target}
						onPhoto={p => this.add_phot(p)}
						inline={inline}
						enableImageUpload={true}
                        acceptedTypes={this.props.acceptedTypes}
                        uploadType={this.props.uploadType}
					/>
				}
				
			</Card.Group>

			<Modal open={!!show}
				onClose={_=>this.setState({show:false})}
				closeIcon className="max-w-[500px] rounded-lg">
				<Modal.Header content={show?.comn || show?.img_data || 'Untitled'} className="break-words !border-0"/>
				<Modal.Content>
					{show && <div 
                        className="w-full mx-auto rounded-lg bg-contain h-[400px] bg-center bg-no-repeat mb-5"
                        data-testid="original-image"  
						style={{backgroundImage: `url(${show.url || `https://storage.googleapis.com/${show.bct}/${show.img_fn}`})`}}/>}
					<Button basic fluid content="Close" onClick={_=>this.setState({show:false})}/>
				</Modal.Content>
			</Modal>
		</React.Fragment>
	}

}

export default Photos;