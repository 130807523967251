import app from 'ampersand-app'
import parser from 'ua-parser-js'
import { app_runtime } from './runts'
import org_model from 'models/org_model'
import moment from 'moment'
if (__RUN_MODE__=='loc')
	window.app = app

const VERIF_ENABLED = true
const MFA_ENABLED = true

const dev = {
	local:["localhost:9011","localhost:8100","icosm.test:9011","inprep.test:9011","ilegal.test:9011"],
	remote:["scripts-dev.firebaseapp.com", "scripts-dev.web.app", "iscr-dev.firebaseapp.com","iscr-dev.web.app",  "d.iscr.com.au",  "t.iscr.com.au", "pt.iscr.com.au","ilegal.web.app","icanna.web.app"],
	remote2 : ['iscr-dev2.web.app',"d2.iscr.com.au"],
	remote3 : ['iscr-dev3.web.app',"d3.iscr.com.au"],
	remotecosm : ["iscr-dev-cosm.web.app","d.icosm.com.au"],
}

app.extend({

	init () {
	},

	user: {
		user_in: null,

		user_obj: null,
		user_det: null,
		user_prof: null,


		user_ready: false,
		user_types: [],

		isLoggedIn: false,		// is the current user signed in

		curr_user: null,

		prefs:{},

		enrolledFactors:[]
	},
	
	acl : {
		get is_admin() { return app.user?.claims?.admin },

		get is_supp() { return app.user?.claims?.supp },
		get is_supp_mang() { return app.user?.claims?.supp && app.user?.claims?.mang },
		get is_supp_patho() { return app.user?.claims?.supp && app.user?.prof?.xtra?.supp_patho },
		get is_mark() { return app.user?.claims?.mark },

		get is_doc() { return app.user?.claims?.doc && app.user.claims.doc_id },
		get is_doc_gp() { return this.is_doc &&  org_model.org_conf_itm(app.user.org, 'is_GP') },
		get is_doc_fracgp() { return this.is_doc_gp && app.user.doc_det?.qualification?.toUpperCase().includes('FRACGP') },
		get is_under_moratorium() {
			return Boolean(
				app.user?.doc_det?.moratorium_date
				&& /^\d{4}-\d\d-\d\d$/.test(app.user.doc_det.moratorium_date)
				&& moment().isBefore(app.user.doc_det.moratorium_date)
			);
		},
		get is_doc_presc() { return this.is_doc &&  org_model.org_conf_itm(app.user.org, 'is_presc') },
		get is_doc_admin() { return this.is_doc &&  org_model.org_conf_itm(app.user.org, 'usr_admin') },

		get is_mang() { return app.user?.claims?.mang && app.user.claims.oid },
		get is_mang_cosm() { return this.is_mang && (app.user.org.type=='cosm'||app.user.org.type=='leg') },
		get is_mang_pharm() { return this.is_mang && app.user.org.type=='pharm' },

		get is_spon() { return (app.user?.claims?.nurse || this.is_mang) && app.user.org.type === 'spon' },

		get is_nurse() { return app.user?.claims?.nurse && app.user.claims.oid && app.user.org.type=='cosm' },
		get is_leg_firm() { return app.user.org.type=='leg' },
		get is_leg_agree() { return app.user.prof?.meta?.ileg_agree == 'yes'},
		get is_leg_oper() { return this.is_leg_firm && this.is_leg_agree && app.user.claims?.nurse},

		get is_cosm() { return this.is_mang_cosm || this.is_nurse },
		get is_pharm() { return app.user?.claims?.pharm && app.user.claims.oid && app.user.org.type=='pharm' },

		get is_work() { return this.is_admin || this.is_supp || this.is_mark || this.is_doc_gp || this.is_doc || this.is_mang || this.is_nurse || this.is_pharm  },
		get is_usr() { return app.user?.user_in && !this.is_work },
		get is_not_usr() { return !app.user.user_in },

		get is_NP() { return (app.user?.doc_det?.prescribertype === 'U'||app.user?.doc_det?.prescribertype === 'NP') && app.user?.doc_det?.qualification === 'Nurse Practitioner' /*&& app.user?.prof?.xtra?.can_minor_comp*/ },
		
		get app_use() { return !( app.dvc.org?.type=='pharm' || (app.dvc.org?.type=='cosm' || app.user.org?.type=='leg') && !['pharm', 'patho'].includes(app.state.cat_nm) )  },
		
		get is_cmg_agree() { return app.user.prof?.meta?.cmg_consent == 'yes'},

		// maybe we should allow patients to blur? basically anyone but nurses
		get can_blur_video() { return (this.is_doc || this.is_admin);  },

		get has_history() { return app.acl.is_usr && app.user?.profs?.find((prof) => prof.pts_scrs > 0) },

		get need_mfa() { return MFA_ENABLED && (this.is_admin  || this.is_supp || this.is_doc_gp || this.is_doc || this.is_mark ) 
							// && app.user.uid != "UgUUHj16eVhTLlmRYS9Wb18N9N32" // Greg
							// && app.user.uid != "szSfTMMaTYWI5YmZ1pk049PAg0n2" // Greg (cosm)
							// && app.user.uid != "jFxCPYcfciPFteTdrR4JYUZt6ct1" // Mira
							// && app.user.uid != "jLtIUHck1ncaREQVI96Oeguzsix2" // Sharon
							// && app.user.uid != "BjJa6ypaOpOiXuCDxvYsw53vDAY2" // Daniel
							// && app.user.uid != "EFFXN0ilvYUqKskLtNoVPzBWiOq2" // Demo
							// && app.user.uid != "HlqyIXXcc6dgAQ4XfAqx6A1vs0n1" // Mohamed Mohd
							// && app.user.uid != "iaEolFB5NBh3RJatEsTsjtZMGhn2" // Hugh O'Sullivan
							// && app.user.uid != "sWZfHB3muxOL2xbQ4FyOe1bCmLx1" // Abeera Sivapalasingam
							// && app.user.uid != "0cm7I2mcftX0m9OlrdXP24vrdGC2" // Fletcher
							// && app.user.uid != "kwjJdhFvXgf4O9rYoIE7AJIbOz12" // Maria !
							// && app.user.uid != "1nOOJr8bFPOQXsNip7aKb8cYprz1" // GPdoc PTest
							&& app.settings.can_mfa 
							&& !app.acl.has_mfa	 },
		get has_mfa() { return app.user.enrolledFactors.length > 0 },
		
		get is_blocked() {
			return (app.user.is_email_blocked 
					|| app.user.is_mfa_blocked || app.user.is_terms_and_conditions_blocked) && !app.settings.is_local
		},

		get can_take_icann() {
			return this.is_doc_gp && [
				"UgUUHj16eVhTLlmRYS9Wb18N9N32", // Greg
				"jFxCPYcfciPFteTdrR4JYUZt6ct1", // Mira
				"EFFXN0ilvYUqKskLtNoVPzBWiOq2", // Demovki
				"Bkqlltu28MOEdTkXIUnRdKsu01o2", // mmcwann@gmail.com
				"wqCOQCyqrJVZRTMmRaOfcTdPKs73", // markrooney@gmail.com
			].includes(app.user.uid);
		},

		get hide_rev_cons() {
			// will return true if set to true or if never been set.
			return app?.user?.prof?.xtra?.hide_rev_cons;
		},

		get view_wr_limit() {
			return app?.user?.prof?.xtra?.view_wr_limit;
		},

		get can_take_mhcp() {
			return app.acl.is_doc_fracgp && !app.acl.is_under_moratorium;
		},

		get can_onboard_doc() {
			return app.acl.is_admin || app.acl.is_supp;
		},

		get can_onboard_nurse() {
			return app.acl.is_admin || app.acl.is_supp;
		},

		get can_urgent_care() {
			return app.acl.is_admin || app.acl.is_supp || app.acl.is_doc_gp;
		},

		get can_manual_review() {
			return (app.acl.is_admin || app.acl.is_supp) && !app.acl.is_doc;
		},

		get can_access_mims() {
			return app.acl.is_admin || app.acl.is_doc_gp || app.user?.prof?.xtra?.can_mims
		},

		
	},

	dvc: {
		dvcid: null,
		org : {}
	},

	docs: {},

	app_data: {
		ws_conf: null,
		ws_data: null,
	},
	
	state : {
		loaded : false,
		cat_nm : null,
	},
	
	get runtime() {
		if (!this.__runtime) {
			this.__runtime = app_runtime(window.__.config, window.location)
		}
		return this.__runtime
	},

	set runtime(new_runtime) {
		this.__runtime = new_runtime
	},

	settings: {

		is_ionic: __IONIC__,
		is_device: parser().device,
		is_mobile: parser().device&&parser().device.type == "mobile",
		is_tablet: parser().device&&parser().device.type == "tablet",

		is_ios: navigator.userAgent.match(/(iPad|iPhone|iPod)/g) || /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1),
		is_mob: navigator.userAgent.match(/Mobile|iP(hone|od|ad)|Android|Opera M(obi|ini)/g) || /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1),

		is_mob_sz: window.innerWidth<=640,

		get is_term() { return ['pharm', 'cosm'].includes(app.dvc?.org?.type) },

		is_dev: document.location.href.startsWith('https://localhost:9011/') 
				|| document.location.href.startsWith('http://localhost:9011/'),

		get redirect_to_signup() {
			return !!app.dvc?.new && app.settings.can_create_account
		},

		signin_on_signup : true,
		
		get is_ionic_ios() {
			return window.device?.platform == "iOS"
		},
		
		get is_ionic_android() {
			return window.device?.platform == "Android"
		},
		
		get is_bare() {
			return /*['/org-signup','/hivprevent'].includes(window.location.pathname) || */ app.runtime.is_bare || app.runtime.mode == 'caia'
		},

		get is_ios_homescreen_app() {
			// https://stackoverflow.com/a/17989939
			return this.is_ios && this.is_mob && 'standalone' in navigator && navigator.standalone;
		},

		get has_troubleshoot() {
			return  ['cosmapp.instantscripts.com.au', 'instantcosmetics.com.au', 'icosm.com.au','d.icosm.com.au', 'pharmapp.instantscripts.com.au'].includes(location.host)
		},

		get is_local() {
			return  __RUN_MODE__=='loc'
		},
		
		get is_test() {
			return ["scripts-dev.firebaseapp.com", 't.iscr.com.au'].includes(location.host)
		},

		get verification_days() {
			return app.settings.grace_days('verif')
		},

		get mfa_days() {
			return app.settings.grace_days('mfa_st')
		},

		get now_open() {
			return moment().tz("Australia/Melbourne").format('H') * 1 >= 6 || moment().tz("Australia/Melbourne").format('H') * 1 < 3
		},

		grace_days(target, v) {

			const val = v || app.user.prof?.extras?.[target];
			
			const grace_period = 1
				// || (app.acl.is_admin || app.acl.is_supp) && 1*7
				// || app.acl.is_doc_gp && 1*7
				// || app.acl.is_doc && 1*7
				// || 3
			
			return Math.max(0,moment(val).add(grace_period+1,'day').diff(moment(),'days'))
		},

		get is_IN_APP_VIDEO_SUPPORTED() { return !!(navigator?.mediaDevices?.getUserMedia) },
		get is_COSM_INSTCONS_NEED() { return app.site_status.instcons_avail || app.site_status['instcons_avail_'+org_model.org_conf_itm(app.dvc.org, 'instcons_avail_suff')] /*|| org_model.org_conf_itm(app.dvc.org, 'instcons_avail')*/ },
		get is_COSM_WEBRTC_ENABLED() { return !!(app.site_status.instcons_webrtc) && app.settings.is_IN_APP_VIDEO_SUPPORTED },
		get is_COSM_INSTCONS_ENABLED() { return app.settings.is_COSM_INSTCONS_NEED && app.settings.is_COSM_WEBRTC_ENABLED && app.settings.is_IN_APP_VIDEO_SUPPORTED },
		get is_COSM_INSTCONS_LOG() { return app.settings.is_COSM_INSTCONS_NEED && !app.settings.is_COSM_INSTCONS_ENABLED },
		get is_COSM_GROUP_SIMILAR() { return !!(app.site_status.instcons_group_similar) },
		get is_COSM_NONCONS_REVIEW() { return !!app.site_status.cosm_require_doc_review },
		get is_COSM_NONCONS_REVIEW_UPCOMING() { return typeof app.site_status.cosm_require_doc_review !== 'boolean' },
		get is_exprcons_avail() { return app.site_status.exprcons_avail !== false },
		get can_promote_appscr() { return app.site_status.appscr_promote !== false },
		get is_multiscript_avail() { return app.site_status.cosm_multiscript_avail !== false },
		get want_multiscript() { return app.acl.is_cosm },

		get min_prescribing_call_duration() {
			if (typeof app.user?.prof?.xtra?.min_call_presc_s === 'number') {
				return app.user?.prof?.xtra?.min_call_presc_s;
			}

			return app.site_status.min_call_presc_s ?? 0;
		},

		get appscr_max_lock() { return app.site_status.appscr_max_lock },
		get appscr_review_delay() { return app.site_status.appscr_review_delay },

		get use_COSM_INSTCONS_TOK () { return true },

		get dev_env_local() {  return dev.local.includes(window.location.host) },
		get dev_env_remote() {  return app.settings.dev_env_remote1 || app.settings.dev_env_remote2 || app.settings.dev_env_remote3 || app.settings.dev_env_remotecosm },
		get dev_env_remote1() {  return dev.remote.includes(window.location.host) },
		get dev_env_remote2() {  return dev.remote2.includes(window.location.host) },
		get dev_env_remote3() {  return dev.remote3.includes(window.location.host) },
		get dev_env_remotecosm() {  return dev.remotecosm.includes(window.location.host) },
		get dev_env() {  return app.settings.dev_env_local || app.settings.dev_env_remote  },
		get test_env() {  return app.settings.dev_env },
		get frb_prod() { return  __PRODUCTIONDB__ && !app.settings.dev_env_remote },
		get pp_prod() { return  __PRODUCTIONDB__ && !app.settings.dev_env_remote },

		get iscr() { return app.runtime.app == 'iscr' },
		get icosm() { return app.runtime.app == 'icosm' },
		get iprep() { return app.runtime.app == 'iprep' },
		get iprep_avail() { return this.iprep && app.runtime.med_allow_excl; },
		get icann() { return app.runtime.app == 'icann' },
		get icann_avail() { return this.icann && (app.site_status?.icann_avail !== false || app.runtime.mode != 'icann'); },
		get ileg() { return app.runtime.app == 'ileg' },
		get is_cbls() { return app.runtime.app == 'icann' && app.runtime.mode == 'cbls' },
		get is_cmg() { return app.runtime.app == 'icann' && app.runtime.mode == 'cmg' },
		
		get can_create_account() { return !app.settings.ileg },
		
		get can_mfa() { return true },

		get show_paypal () {
			if(!app.site_status) return true
			else if(!app.settings.is_ionic && app.site_status?.disable_paypal.web === false) return true
			else if(app.settings.is_ionic_ios && app.site_status?.disable_paypal.ios === false) return true
			else if(app.settings.is_ionic_android && app.site_status?.disable_paypal.android === false) return true
			return false
		},

		get onepass_avail() { 
			return app.site_status?.onepass_avail && (!app.settings?.is_ionic || (app.settings?.is_ionic && app.site_status?.allow_op_on_native && window.__NATIVE_OAUTH__))
		}
		
		// is_mob: navigator.userAgent.match(/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/g),
/*		hasFlash: false,		// does the current client have flash
		couldHaveFlash: false,	// could hte current client install flash
		flash_enabled: true,	// is flash working http://stackoverflow.com/questions/2192203/is-there-a-way-to-detect-flash-blockers

		is_mobile: false,		// is it runs on mobile device
		mobile_type: null,		// determine device for known type - iPad, iPhone, Android
		can_app: false,			// determine if device have app (iPad, iPhone, Android)
*/
	},
	
	notifications: {
		allowed:false,
		blocked:false
	},


	functions: {
		show_popup: null,
		add_message:(...args)=>app.trigger(app.events.SHOW_MESSAGE,...args) 
		
	},


	events: {
		BOUNCE_RTDB: 'BOUNCE_RTDB',

		USER_UPD: 'USER_UPD',
		CONSENT_UPD: 'CONSENT_UPD',

		SHOW_POPUP: 'SHOW_POPUP',

		SHOW_MESSAGE: 'SHOW_MESSAGE',
		CLEAR_MESSAGE: 'CLEAR_MESSAGE',
		SHOW_PAGE: 'SHOW_PAGE',
		IDLE_START: 'IDLE_START',
		IDLE_END: 'IDLE_END',
		IDLE_RESET: 'IDLE_RESET',
		
		CONFIRM: 'CONFIRM',

		SMS_CONFIRM: 'SMS_CONFIRM',

		REQUEST_LOGIN: 'REQUEST_LOGIN',
		REQUEST_JOIN: 'REQUEST_JOIN',
		REQUEST_LOGOUT: 'REQUEST_LOGOUT',

		REQUEST_EDITPROFILE: 'REQUEST_EDITPROFILE',

		REQUEST_PASS_RESET_VERI: 'REQUEST_PASS_RESET_VERI',
		
		PEER_CALL : 'PEER_CALL',

		SITE_READY : 'SITE_READY',

		CONTEXT : 'CONTEXT',

		MFA_CONFIRM : 'MFA_CONFIRM',
		NEED_RESULTS: 'NEED_RESULTS',

		SET_INSTCONS_WR_NOTIFICATION_VISIBILITY: 'SET_INSTCONS_WR_NOTIFICATION_VISIBILITY',
		INSTCONS_WR_PENDING: 'INSTCONS_WR_PENDING',

		USER_CONTEXT: 'USER_CONTEXT',
		PRS: 'PRS',

		SEARCH: 'SEARCH',

		DVC_LDD: 'DVC_LDD',

		PRESCRIBE_AGAIN: 'PRESCRIBE_AGAIN',
	},

	history: {},
	
	confirm: (title,content,config = {}) => {
		return new Promise((resolve)=>{
			app.trigger(app.events.CONFIRM, {
				title,
				content,
				...config
			}, resolve )
		})
		
	},

	sms_confirm: (reason, header) => {
		return new Promise((resolve)=>{
			app.trigger(app.events.SMS_CONFIRM, {
				reason,
				header
			}, {resolve} )
		})
		
	},

	mfa_confirm: (resolver) => {
		return new Promise((resolve, reject) => {
			app.trigger(app.events.MFA_CONFIRM, resolver, { resolve, reject });
		});
	},

	consts: {
		routes: {
			cat_route : "/c",
			med_route : "/m"
		}
	},

	localStorage: new Proxy(localStorage ?? {}, {
		get(_, prop) {
			const res = localStorage.getItem(prop);
			try {
				return JSON.parse(res);
			} catch (_) {
				return res;
			}
		},
		set(_, prop, value) {
			localStorage.setItem(prop, JSON.stringify(value));
			return true;
		},
		deleteProperty(_, prop) {
			localStorage.removeItem(prop);
			return true;
		}
	}),
	
/*
	login (u) {
		this.settings.isLoggedIn = true
		this.settings.curr_user = u

		// console.log('login : ', this.settings.curr_user)

		this.settings.curr_user.pr >0 && 
			app.trigger(app.events.SHOW_MESSAGE, '', 
			            'YOU\'VE WON A PRIZE CARD!',
			            {u:'game/My-Prize-Cards', t:'CHOOSE >'}
			        )
	},

	logout () {
		this.settings.isLoggedIn = false
		this.settings.curr_user = null
	},

	show_page (u) {
		// console.log("show_page event with:", u) 
		app.trigger(app.events.hide_user_panel)
		switch(u) {
			case 'home':
				browserHistory.push('/')
				break
			case 'game-cards':
				browserHistory.push('/cards')
				break
			case 'avatar':
			case 'my-profile/avatar':
				browserHistory.push('/game/Avatar-Shop')
				break
			case 'avacont':
				browserHistory.push('/game/Avatar-Contest')
				break
			default:
				console.log("show_page event with:", u) 
				browserHistory.push('/game/' + u)
				break
		}
	},

	start () {
		ga.initialize(app.app_data.ws_conf.conf.ga_acc.an, { debug: false });
		// ga.pageview(location.pathname)
		const loclisten = browserHistory.listen(location => {
			// ga.send('send', location);
			// console.log("browserHistory:location", location)
			ga.pageview(location.pathname)
		})

		renderSite()
	},
*/

})

export default app
