import React, { useEffect, useState, useMemo } from 'react';
import { Checkbox, Input, Loader, Segment } from 'semantic-ui-react';
import { useProfile } from 'xAppLib/Hooks';
import myhr_model from 'models/myhr_model';

const ENABLE_MY_HR = false

const modes = {
	PROFILE: 'profile',
	SCRIPT: 'script',
};

const AccessMyHR = ({ onUpdateAccess, mode = modes.PROFILE }) => {
	if (!ENABLE_MY_HR)
		return  null
	const [profile] = useProfile();
	const user_exists = useMemo(() => {
		return profile.myhr?.exists;
	}, [profile.myhr?.exists]);

	const user_enrolled = useMemo(() => {
		return profile.myhr?.user_upload_pref
	}, [profile.myhr?.user_upload_pref]);

	const [active, setActive] = useState(!!profile.myhr?.user_upload_pref);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setActive(user_enrolled);
	}, [user_enrolled]);

	useEffect(() => {
		if (!user_exists) {
			(async () => {
				await myhr_model.has_access();
				await app.user.reload_profile();
			})();
		}
	}, []);

	useEffect(() => {
		onUpdateAccess?.(active);
	}, [active]);

	const handleAccessToggle = async () => {
		setLoading(true);
		const new_enrollment_status = !active;
		let res = false;
		if (mode === modes.PROFILE || profile.myhr?.user_upload_pref === undefined) {
			const response = await myhr_model.toggle_enrollment(new_enrollment_status);
			await app.user.reload_profile();
			res = response?.user_upload_pref || false;
		}
		setActive(res || new_enrollment_status);
		setLoading(false);
	};

	if (!user_exists) {
		return <></>;
	}

	return (
		<>
			<Segment basic={mode == modes.SCRIPT} style={mode == modes.SCRIPT ? { padding: 0 } : {}}>
				{mode != modes.SCRIPT && (
					<p>
						<strong>My Health Record</strong>
					</p>
				)}
				<p>
					You can connect your InstantScripts account to My Health Record and upload prescription records and other documents to
					share with other medical facilities such as your regular GP or hospital.
				</p>
				<p>You can still opt-out to send information to My Health Record for specific request if you wish to do so.</p>
				<div className='flex flex-col gap-2'>
					<Checkbox
						toggle
						disabled={loading || !user_exists}
						checked={active}
						label='Send records to My Health Record'
						onChange={() => handleAccessToggle()}
					/>
					{loading && <Loader inverted inline />}
				</div>
			</Segment>
		</>
	);
};

Object.assign(AccessMyHR, modes);

export default AccessMyHR;
